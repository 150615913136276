@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');


/* < !----Eventslist page css ---> */
.eventslist {
  margin: 50px 10px;
}

.eventslist-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Tabs styling */
.eventslist-container .tabs {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.eventslist-container .tab {
  padding: 10px 20px;
  border: 1px solid #1F9BCF;
  color: #4F5459;
  /* Font-family: Poppins; */
  font-weight: 500;
  line-height: 15px;
  background-color: white;
  border-radius: 5px;
}

.eventslist-container .tab.active {
  background: #1F9BCF;
  color: white;
}

.eventslist-container input.checkbox-collection {
  height: 15px;
}

.eventslist-table-container .btn,
.eventslist-table-container .btn:hover {
  padding: 6px 20px;
  border: none;
  /* background: #1F9BCF; */
  /* Font-family: Poppins; */
  font-weight: 500;
  color: white;
  float: right;
  top: 24px;
  position: relative;
  margin-bottom: 55px;
  border-radius: 5px;
}

.eventslist-table-container {
  width: 100%;
  overflow-x: auto;
}

.eventslist-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.eventslist-table-container td {
  /* padding: 12px;
  border: 1px solid #B9B9B9;
  font-family: inter;
  font-size: 14px; */
}

.eventslist-table-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.eventslist-table-container .search-box {
  margin-bottom: 10px;
  width: 55%;
  /* padding: 10px; */
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-border {
  color: red;
  margin-top: 5px;
  font-size: 0.9rem;
  border: 1px solid red !important;
}

.eventslist-table-container tr:hover {
  background-color: #f1f1f1;
}

.eventslist-table-container th {
  background-color: #f0f0f0;
  padding: 12px;
  border: 1px solid #B9B9B9;
  font-family: inter;
  font-size: 14px;
}

.event-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem; /* Matches mt-4 */
}

.eventslist-table-container .name-event {
  text-decoration: none;
  color: #2e3192;
  font-family: inter;
  font-weight: 500;
  background: #f4f4f4;
  padding: 6px;
  border-radius: 3px;
}
.eventslist-table-container .name-event1 {
  text-decoration: none;
  color: #2e3192;
  font-family: inter;
  font-weight: 500;
}

.eventslist-table-container .name-event:hover{
  color: #fff;
  background: #2e3192;
}

.eventslist-table-container .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 6px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.eventslist-table-container .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.eventslist-table-container .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.eventslist-table-container .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}



input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.eventslist-table-container .slider.round {
  border-radius: 34px;
}

.eventslist-table-container .slider.round:before {
  border-radius: 50%;
}

.eventslist .search {
  padding: 11px 20px;
  border: none;
  background: #1F9BCF;
  /* Font-family: Poppins; */
  font-weight: 500;
  font-size: 14px;
  top: -2px;
  color: white;
  border-radius: 5px;
  position: relative;
}

/* < !----Eventslist page css ends here --->< !----Details Event page css --->. {} */

.detailsevent .events {
  top: -3px;
  position: absolute;
  right: 0;
}

.detailsevent-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 2px solid #252775;
}

.detailsevent-container .tabs {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.detailsevent-container .tab {
  padding: 15px;
  color: #4F5459;
  /* Font-family: Poppins; */
  font-weight: 500;
  line-height: 15px;
  background-color: #ECF9FE;
}

.detailsevent-container .tab.active a {
  color: #fff;
}

.detailsevent-container .tab.active {
  background: #252775;
  color: white;
}

/*.detailsevent-table-container .btn,
.detailsevent-table-container .btn:hover {
  padding: 10px;
  border: none;
  background: #252775;
  /* Font-family: Poppins; 
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  right: 0;
  top: 25px;
  border-radius: 50px;
  float: right;
  /* position: absolute; 
  margin-bottom: 20px;
}*/

.detailsevent-table-container .btn, 
.detailsevent-table-container .btn:hover {
  /* padding: 10px; */
  border: none;
  background: #252775;
  /* Font-family: Poppins; */
  font-weight: 500;
  /* display: flex
; */
  align-items: center;
  color: white;
  /* right: 0; */
  top: -7px;
  /* left: -29px; */
  /* border-radius: 50px; */
  /* float: right; */
  /* position: absolute; */
  margin-bottom: -14px;
}

.detailsevent-table-container {
  width: 100%;
  overflow-x: auto;
}

.detailseventpagination .pagination {
  display: inline-block;
}

.detailseventpagination .pagination.a {
  border: 2px solid #DFE3E8;
  color: black;
}

.detailseventpagination {
  margin: 10px;
}

.detailseventpagination .save-draft {
  padding: 6px 20px;
  border: 2px solid #1F9BCF;
  color: #1F9BCF;
  background: none;
  border-radius: 5px;
  /* Font-family: Poppins; */
  font-size: 14px;
  font-weight: 600;
}

.detailseventpagination .publish {
  padding: 6px 20px;
  border: 2px solid #2246b;
  color: white;
  background: #252775;
  border-radius: 5px;
  /* Font-family: Poppins; */
  font-size: 14px;
  font-weight: 600;
  margin-top: 14px !important;
}

.detailseventpagination .buttons {
  Display: flex;
  float: right;
  gap: 10px;
}

.detailseventpagination .pagination a {
  color: black;
  float: left;
  border: 2px solid #DFE3E8;
  margin: 3px;
  padding: 5px 10px;
  text-decoration: none;
}

.event_photos_btns {
  position: absolute;
  top: 1.5rem;
  right: 0;
}

.detailseventpagination .pagination .active {
  border: 2px solid #1F9BCF;
  color: #1F9BCF;
}

.detailseventimages .eventtext {
  position: absolute;
  top: 14px;
  color: white;
  background: black;
  padding: 2px;
  left: 55px;
  font-size: 14px;
  /* Font-family: Poppins; */
}

.detailsevent-table-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0px;
}

.detailsevent-table-container td {
  padding: 12px;
  border: 1px solid #e4e4e4;
  font-family: inter;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 15.6px;
  vertical-align: middle;
}

.detailsevent-table-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.detailsevent-table-container .search-box {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 20px;
}

.eventform .host {
  /* Font-family: Poppins; */
  Font-weight: 600;
  font-size: 17px;
  Line-height: 24.5px;
  margin: 20px 0 0 0;
}

.detailsevent .qr-image {
  border: 1px solid #ccc;
  padding: 0;
}

/* .detailsevent .qr-image {
  width: 250px;
  height: 250px;
  margin: 125px auto 20px;
} */

.detailsevent .download-qr {
  padding: 10px;
  border: none;
  background: #252775;
  /* Font-family: Poppins; */
  font-weight: 500;
  font-size: 13px;
  line-height: 19.5px;
  color: white;
  border-radius: 10px;
  margin: auto;
  width: 100%;
}

.detailsevent-table-container tr:hover {
  background-color: #f1f1f1;
}

.detailsevent-table-container th {
  background: #ecf9fe;
  padding: 12px;
  border: 1px solid #e4e4e4;
  font-family: inter;
  line-height: 15.6px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  box-shadow: none;
}

.detailseventimages {
  position: relative;
}

.detailseventimages .edit-img {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  left: 15px;
  background: white;
  top: 15px;
  padding: 5px;
  cursor: pointer;
}

.detailseventimages .delete-img {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  right: 18px;
  background: white;
  top: 0px;
  padding: 5px;
  cursor: pointer;
  color: rgb(220 53 69);
  font-size: medium;
}

.details-images-container .row.mx-0 {
  gap: 4px;
}

.details-images-container .main-img {
  object-fit: cover;
  border-radius: 5px;
}

.detailsevent .paidevent {
  position: relative;
  top: 15px;
  vertical-align: middle;
  align-items: center;
  display: flex;
}

.detailseventimagesrow {
  margin: auto;
}

.detailseventupload {
  background: #F5FCFF;
  height: 291px;
  vertical-align: middle;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #1F9BCF8C;
}

.detailseventupload .btnphoto {
  padding: 10px;
  border: none;
  cursor: pointer;
  background: #1F9BCF;
  /* Font-family: Poppins; */
  font-weight: 500;
  font-size: 13px;
  line-height: 19.5px;
  color: white;
  border-radius: 10px;
  margin: auto;
}

.detailseventupload #dropZone,
.detailseventupload #dropZone1 {
  cursor: pointer;
}

.detailseventupload .uploadtext {
  /* Font-family: Poppins; */
  Font-weight: 600;
  font-size: 16px;
  Line-height: 40px;
}

.detailseventupload .uploadicon {
  font-size: 32px;
}

.detailseventupload input[type="file"] {
  display: none;
}

.detailsevent .label {
  display: inline-block;
  /* Font-family: Poppins; */
  Font-weight: 600;
  font-size: 14px;
  Line-height: 20px;
  padding: 0;
  color: #444444;
  margin-bottom: 10px;
}

.detailsevent .createcollection {
  font-weight: 500;
  color: #444444;
  font-size: 14px;
  /* Font-family: Poppins; */
  line-height: 22px;
}

.tooltip-inner {
  max-width: 250px;
  text-align: center;
}

.detailsevent #createcollection {
  top: 2px;
  position: relative;
}

.detailsevent .eventbtn {
  padding: 10px;
  border: none;
  background: #252775;
  font-weight: 500;
  font-size: 13px;
  color: white;
  border-radius: 6px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.detailsevent .eventstatus {
  /* Font-family: Poppins; */
  font-weight: 600;
  font-size: 14px;
  color: #444444;
  line-height: 20px;
  margin: 0px 10px;
  position: relative;
}

/* <!----Details Event page css ends here --- >			 */

/* <!----Create Event page css --- >	 */
.eventform label {
  /* Font-family: Poppins; */
  Font-weight: 600;
  font-size: 16px;
  Line-height: 34px;
}

.eventform select,
.eventform .input {
  width: 100%;
  color: #444444;
  height: 40px;
  /* Font-family: Poppins; */
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #CED4DA;
  padding: 10px;
}

.eventform input, .eventform select, .eventform textarea{
  border: 1px solid #d9d9d9;
  width: 100% !important;
}

.eventform select[readonly], .eventform input[readonly], .eventform textarea[readonly], input#start_date[readonly], input#end_date[readonly]{
  background: #f8f8f8;
  width: 100%;
  border: 1px solid #d9d9d9;
}

.profile_nav{
  margin-bottom: 3rem;
}

.profile_nav.sticky-top{
  margin-bottom: 2rem;
  top: 73px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
}

.profile_nav .list-inline-item{
  padding: 10px 0;
}

@media (max-width: 1400px){
  .profile_nav.sticky-top{
    top:62px;
  }
}

.createevent .host {
  /* Font-family: Poppins; */
  Font-weight: 600;
  font-size: 17px;
  Line-height: 24.5px;
  margin: 20px 0 0 0;
}

.createevent {
  margin: 50px 10px;
}

.eventformcheck .collections,
.eventformcheck .sponsor {
  height: 15px;
  width: 20px;
  position: relative;
  top: 2px;
}

.createevent .submit {
  padding: 10px;
  border: none;
  background: #1F9BCF;
  /* Font-family: Poppins; */
  font-weight: 500;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

.createevent .label {
  display: inline-block;
  /* Font-family: Poppins; */
  Font-weight: 600;
  font-size: 14px;
  Line-height: 20px;
  padding: 0;
  color: #444444;
  margin-bottom: 10px;
}

.createevent .paidevent {
  position: relative;
  top: 15px;
  vertical-align: middle;
  align-items: center;
  display: flex;
}

input#start_date,
input#end_date {
  width: 100%;
  color: #444444;
  height: 40px;
  /* Font-family: Poppins; */
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #CED4DA;
  padding: 10px;
}

/* <!----Create Event page css ends here --- >		 */

/* <!----User registration page css--- > */

.userregistration .usereventname {
  text-align: center;
  padding: 5px;
  font-size: 30px;
  /* Font-family: Poppins; */
  font-weight: 600;
  line-height: 45px;
  color: #2E3192;
  margin: 10px 0px;
  width: 100%;
}

.userregisterlogo {
  background-color: #2E3192;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  height: 86px;
  vertical-align: middle;
  align-items: center;
}

.usereventhead {
  font-family: Inter;
  font-weight: 500;
  font-size: 18px;
  color: #5F607C;
  line-height: 21.78px;
  text-align: center;
  width: 100%;
}

.usereventsubhead {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #5F607C;
  line-height: 28px;
  text-align: center;
  margin: 5px;
}

#selfie-text img {
  margin: 5px auto;
}

.userregistration .uploadedimg {
  width: 124px;
  height: 124px;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
}

.userregistration .successfullsection {
  height: 250px;
  align-items: center;
}

.userregistration #countdown {
  Font-family: inter;
  Font-weight: 500;
  font-size: 14px;
  color: #000000;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid #000000;
  margin: auto;
  padding: 12px 10px;
}

.userregistration .text-seconds {
  font-family: inter;
  font-weight: 500;
  color: #5F607C;
  font-size: 14px;
  margin-top: 10px;
}

.userregistration .congratstext {
  font-size: 20px;
  font-weight: 500;
  font-family: inter;
  line-height: 33px;
  color: #5F607C;
  margin: 20px 0px;
}

.userregistration .registertext {
  font-size: 18px;
  font-weight: 500;
  font-family: inter;
  line-height: 26px;
  color: #5F607C;
  margin: 20px 0px;
}

.userregistration .congrats {
  color: #28B621;
  Font-weight: 600;
}

.userregistration .finalpage {
  height: 800px;
}

.userregistration .checkicon {
  color: #28B621;
  font-size: 28px;
  border: 3px solid #28B621;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 8px;
}

.userregistration .camera {
  background: #2E3192;
  position: relative;
  top: -25px;
  padding: 10px;
  border-radius: 50px;
}

.userregistration #selfie-text {
  background: #F8F8F8;
  padding: 20px;
  border: 1px solid #CED4DA;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}

.userregistration .registerbtn {
  background: #2E3192;
  color: white;
  width: 100%;
  Font-weight: 600;
  border: none;
  line-height: 24.2px;
  font-family: inter;
  font-size: 20px;
  margin: 0;
  padding: 15px;
  border-radius: 6px;
}

.userregistration .submittext {
  color: #000000;
  font-family: inter;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  padding: 10px;
  margin: 0;
  Line-height: 24px;

}

.userregistration .input {
  width: 100%;
  color: #444444;
  /* Font-family: Poppins; */
  background: #F8F8F8;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  border: 1px solid #CED4DA;
  border-radius: 6px;
}

.userregistration .selfietext,
.userregistration label {
  /* Font-family: Poppins; */
  Font-weight: 600;
  font-size: 14px;
  Line-height: 20px;
  padding: 0;
  color: #444444;
  margin-bottom: 10px;
  width: 100%;
}

.usereventcheckbox .termstext {
  font-family: inter;
  Font-weight: 400;
  font-size: 14px;
  Line-height: 24px;
  padding: 0;
  color: #000000;
  margin: 0px;
}

.usereventcheckbox {
  gap: 20px;
}

.custom-container {
  max-width: 600px;
  margin: auto;
  padding: 0px 15px;
}

.userregistration .required {
  color: #F50606;
}

input#terms {
  width: 30px;
  top: -6px;
  position: relative;
}
.event_photos_btns {
  position: absolute;
  top: -1.5rem;
  right: 0;
}
.eventphotos-container {
  max-width: 1240px;
  margin: auto;
}

.eventphotos-container .eventrow {
  gap: 5px;
}

.eventphotos-container .eventphoto {
  position: relative;
}

.eventphotos-container .radiocollections {
  position: absolute;
  top: 15px;
  right: 15px;
}

.eventphotos-container input#radiocollections {
  width: 18px;
  height: 18px;
}
.photos_coming {
  width: 300px;
}

.eventphotospagination .pagination {
  float: right;
}

.eventphotospagination .entries {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.68px;
  color: black;
  margin: 0px 10px;
}

.eventphotospagination .pagination a {
  color: black;
  float: left;
  border: 2px solid #DFE3E8;
  margin: 3px;
  padding: 5px 10px;
  text-decoration: none;
}

.eventphotospagination .pagination .active {
  border: 2px solid #1F9BCF;
  color: #1F9BCF;
}

.eventphotos-container .eventlist {
  display: flex;
  list-style: none;
  gap: 15px;
  align-items: center;
  padding: 0px;
  /* Font-family: Poppins; */
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;

}

.eventphotos-container .breadcrumbheading {
  /* Font-family: Poppins; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2E3192;
  margin: 5px 0px;
}

.eventphotos-container .eventname {
  /* Font-family: Poppins; */
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
  color: black;
}

.eventphotos-container .eventpara {
  /* Font-family: Poppins; */
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #808285;
}

.eventphotos-container label {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.68px;
  text-align: center;
  color: #808285;
  margin: 0;
}

.eventphotos-container .selectednobtn {
  padding: 10px;
  border: none;
  background: #2E3192;
  font-family: Outfit;
  font-weight: 600;
  color: white;
  border-radius: 50px;
  font-size: 18px;
}

.eventphotos-container .eventformcheck {
  align-items: center;
  display: flex;
  gap: 10px;
}

.eventphotos {
  margin: 20px;
}

.eventphotos-container .userdetail {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px auto;
  background: white;
  width: 200px;
  padding: 10px;
  border-radius: 50px;
  box-shadow: 0px 0px 15px 0px #0000001A;
}

.eventphotos-container .userdetail p {
  margin: 0;
}

.eventphotos-container .search-bar {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
  background: #d9d9d963;
  width: 360px;
  border: none;
  padding: 10px;
  height: 40px;
  border-radius: 10px;
}

.eventphotos-container .search-bar {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
  color: black;
}

.eventphotos-container .searchsection {
  align-items: center;
  justify-content: flex-end;
}

.eventphotos-container .download {
  justify-content: flex-end;
}

.eventphotos-container .eventnames {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: left;
  color: #000000;
}

.avatar-group {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* Makes avatars circular */
  border: 2px solid #fff;
  /* Optional white border */
  object-fit: cover;
  /* Ensures the image fits properly */
  margin-left: -10px;
  /* Creates the overlapping effect */
}

.avatar:first-child {
  margin-left: 0;
  /* Remove margin for the first avatar */
}

.avatar.more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #ccc;
  /* Background color for the 'more' avatar */
  color: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 16px;
  font-weight: bold;
  margin-left: -10px;
  /* Overlap effect */
}

.detailsevent .flatpickr-input[readonly] {
  cursor: pointer;
  width: 100%;
  color: #444444;
  height: 40px;
  /* Font-family: Poppins; */
  background: #F8F8F8;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #CED4DA;
  padding: 10px;
}

input#datetime {
  width: 100%;
  color: #444444;
  height: 40px;
  /* Font-family: Poppins; */
  background: #F8F8F8;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #CED4DA;
  padding: 10px;
}

.selfie div {
  width: 100%;
  position: relative;
  /* bottom: 0px; */
  /* top: 12px; */
  /* left: 0; */
  /* right: 124px; */
}

.selfie video {
  object-fit: contain;
}

.eventformcheck .img-icon {
  position: absolute;
  top: 11px;
  right: 23px;
  background: #fff;
  padding: 3px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 13px;
  color: #2e3192;
}


.cam_webcam-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.cam_webcam-video {
  width: 100%;
  border-radius: 10px;
  /* transform: scaleX(-1); */
}

/* Apply specific styles only for mobile devices */
@media (max-width: 767px) {
  .cam_webcam-video {
    height: 100vh;
    object-fit: cover;
    border-radius: 0;
  }
  .eventlist {
    display: grid !important;
    gap: 7px !important;
  }
}

.cam_preview-img {
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .cam_preview-img {
    height: 100vh;
    object-fit: cover;
    border-radius: 0;
  }
}

.cam_webcam-canvas {
  display: none;
  /* Hide canvas by default */
}

.cam_webcam-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cam_start-webcam {
  background-color: #333;
  color: #fff;
}

/* Parent container should be positioned relative */
.parent-container {
  position: relative;
  width: 100%; /* Ensure it takes full width */
  height: 100%; /* Adjust based on your div size */
  background-color: #f8f8f8; /* Just for visibility */
  border: 1px solid #ccc; /* Optional */
}

/* Loader overlay inside the div */
.loading-container {
  position: absolute; /* Stays inside the div */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  z-index: 10; /* Ensures it stays above other content */
}

/* Loading text styling */
.loading-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}


/* Parent container should be relative */
.form-container {
  position: relative;
}

/* Loading Overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Keeps it above the form */
}

/* Spinner Animation */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-top: 5px solid #3498db; /* Blue Spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Ensure the container uses flexbox */
.details-images-container .row {
  display: flex;
  flex-wrap: wrap; /* Allows images to wrap if needed */
  justify-content: flex-start; /* Aligns images to the left */
  align-items: flex-start; /* Keeps all images aligned at the top */
  gap: 15px; /* Adds spacing between images */
}

/* Individual image container */
.detailseventimages {
  display: flex;
  flex-direction: column; /* Keeps text/icons below the image */
  align-items: center;
  text-align: center;
  width: 150px; /* Adjust width as needed */
}

/* Ensure images scale properly */
.main-img {
  width: 100%;
  max-width: 150px; /* Set a uniform size */
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

/* Adjust spacing for delete/edit icons */
.delete-img, .edit-img {
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 5px;
}

/* Styling for badges */
.draft-badge {
  margin-top: 5px;
  font-size: 0.9rem;
  padding: 5px 10px;
}

/* edit events display  */
.events {
  display: flex !important;  /* Force flex display */
  flex-direction: row !important;  /* Force elements to be in a row */
  align-items: center;  /* Align items vertically in the center */
  justify-content: flex-start;  /* Align elements to the left */
  gap: 3px;  /* Space between elements */
  flex-wrap: nowrap;  /* Prevent wrapping (adjust if needed) */
}

/* Remove inner borders and fix styling */
.PhoneInput {
  width: 100%;
}

.PhoneInputInput {
  border: none !important;
  outline: none !important;
  background: transparent;
  width: 100%;
  /* padding: 0.375rem 0.75rem; */
}

.PhoneInputCountrySelect {
  border-right: none !important;
  padding-right: 8px;
}

.PhoneInputCountryIconImg {
  object-fit: contain;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: var(--bs-backdrop-bg);
}