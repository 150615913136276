@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.photography-banner {
  background-image: url(../img/photographer_banner_2.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  margin-top: -0.5rem;
}
.banneropacity {
  background-color: rgb(66 97 103 / 50%);
}
.bannerbox {
  padding: 5em 5em 5em 0;
}
.photography-banner .title {
  font-family: Poppins;
  font-size: 46px;
  font-weight: 700;
  line-height: 72px;
  text-align: left;
  color: white;
}
.photography-banner .text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: white;
  text-align: center;
  margin-bottom: 0;
}
.photography-banner .objects {
  border: 1px solid white;
  border-radius: 8px;
  margin-right: 12px;
  width: 134px;
  background: rgba(255, 255, 255, 0.08);
}
.subdiv_photo {
  position: relative;
  padding-bottom: 30px;
}
.tag {
  background-color: #2e3192;
  color: white;
}

.tag p {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 52px;
  text-align: left;
  padding: 0px 20px;
}
.form_head {
  background: #2e3192;
  color: white;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  border-radius: 20px 20px 0px 0px;
  line-height: 37.5px;
  text-align: center;
  margin: 0;
}
.form_input {
  border-radius: 3px;
  border: 1px solid #d9d9d9 !important;
}
.form-select {
  border-radius: 3px;
  border: 1px solid #d9d9d9 !important;
}
.form_box {
  background-color: rgb(255 255 255 / 98%);
  margin: 0;
}
.form_label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #5c5ea9;
}
.form {
  position: relative;
  padding: 0px 20px 0px 200px;
  top: 80px;
}
.form_field {
  padding: 0px 5px;
}
.form_footer {
  background: #5f63ff;
  color: white;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  border-radius: 0px 0px 20px 20px;
  line-height: 30px;
  margin: 0;
  width: 100%;
  text-align: left;
}
.form_icon {
  float: right;
  font-size: 16px;
  top: 6px;
  position: relative;
}
.category {
  background: linear-gradient(#88888800, #000000);
  border-radius: 5px;
  margin: 0px;
  font-family: Outfit;
  bottom: 25px;
  position: absolute;
  right: 0;
  left: 0;
  color: white;
  padding: 20px;
}
.category_icon {
  font-weight: bold;
  color: white;
  float: right;
}
.imagesize1 {
  object-fit: cover;
  height: 269px;
}
.imagesize2 {
  object-fit: cover;
  height: 371px;
}
.imagesize3 {
  object-fit: cover;
  height: 229px;
}
.imagesize4 {
  object-fit: cover;
  height: 195px;
}
.tab_content {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #2e3192 !important;
  background: white;
  margin: 10px;
  width: 200px;
  gap: 20px;
}
.tab_content.active {
  background: #2e3192 !important;
  margin: 10px;
  color: #ffffff !important;
  border-radius: 5px;
}
.tab_content svg{
  scale: 1.2;
}
.tab_content.active svg path {
  fill:#fff
}
.tab_heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: black;
  margin-top: -25px;
}
.aifacesync{
    background-color: #fff;
    height: 300px;
}


/* AI Face Sync Page Css Started */


.banner-ai .heading{
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  }
  .banner-ai .para{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.125px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;	
  }
  .banner-opacity{
    padding: 5em 0 10em 0;	
      height: auto;
      display: grid;
      align-items: center;
  }
  .banner-ai{
  background: url(../img/facesync/ai-bg2.jpg) center center no-repeat;
  background-size:cover;
  
  }
  .video-ai .video-bg{
    top: -90px;
    /* background: url(../img/facesync/border.png); */
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    text-align: center;
    position: relative;

  }
  .video-ai iframe {
    width: 720px;
    height:405px ;
    border-radius: 10px;
  }
  .video-ai .video-control {
    border: 0px solid red;
    padding:3px;
    border-radius: 50%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .video-ai i.fa-solid {
    color: red;
    background:white;
    border-radius:50%;
   }
  .video-ai{
        background: linear-gradient(0deg, rgba(232, 246, 255, 0.6) 0%, #FFFFFF 67.98%);
      height: auto;
      align-items: center;
      padding: 0em 0 5em;
      display: grid;
      position: relative;
  }
  .video-ai .righttop{
  position: absolute;
      right: 75px;
      top: -50px;
      width: 7%;
  }
  .pricing-ai .text h5:hover{
  color: #7d81ed;
      background: white;
    border: 1px solid #7d81ed;
  }
  
  .video-ai .bottomleft{
  position: absolute;
      left: 75px;
      bottom: -50px;
      width: 7%;
  }
  .video-ai h3{
  font-family: Poppins;
  font-size: 35px;
  font-weight: 700;
  line-height: 75px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;	
  margin:-50px 0 0 0;
  }
  .ai-face-sync{
  background:url('../img/facesync/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 0;
  }
  .ai-face-sync .heading{
  font-family: Poppins;
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 20px 0;
  }
  .ai-face-sync .para{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.125px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;	
  width: 80%;
  margin: 20px auto;
  }
  .ai-face-sync-new{
  background:url('../img/facesync/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 0;
  }
  .ai-face-sync-new #text{
  margin:auto;
  padding:0 25px;
  }
  .ai-face-sync-new #image{
  background:url('../img/facesync/Vector.png');
  background-repeat:no-repeat;
  background-size:contain;
  animation: mymove 5s infinite;
  height:500px;
  }
  @keyframes mymove {
    50% {background-position: center;}
  }
  .ai-face-sync-new .heading{
  font-family: Poppins;
  font-size: 44px;
  font-weight: 600;
  line-height: 58px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  }
  .ai-face-sync-new .sub-heading{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  padding:0;
  margin:0;
  }
  .ai-face-sync-new .para{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.125px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;	
  width: 80%;
  margin: 20px 0;
  }
  .ai-face-sync-new .btn-md{
  background: linear-gradient(90.98deg, #2E3192 1.05%, #5D62FB 99.39%);
      padding: 10px 25px;
      border: none;
      color: white;
      border-radius: 6%;
    }
  .how-it-works{
  background:#FBFBFB;
  height:auto;
  }
  .how-it-works .heading{
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 0.125px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;	
  width: 80%;
  margin: 75px auto 25px;
  }
  
  .tabs-ai{
  height: auto;
      align-items: center;
      display: grid;
      padding: 7em 0;	
  }
  .tabs-ai .heading{
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 55px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;	
  }
  .pricing-ai{
  background: linear-gradient(90deg, #7A7EFE 0%, #8084E6, #3036fda8 100%);
      height: auto;
      padding: 2em 0;
      align-items: center;
      display: grid;
      position: relative;
  }
  .pricing-ai .month{
  position: relative;
      top: 20px;
      margin:auto;
      width:150px;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
      line-height: 22.5px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: white;
      border-radius: 5rem;
      border: 1px solid white;
      background: #7c80f5;
      padding: 8px 4px;	
  }
  .pricing-ai .month:hover, .pricing-ai .gst:hover, .pricing-ai .box .gst:hover, .pricing-ai .box .month:hover{
    background: white;
      color: #7a7efe;
  }
  
  .pricing-ai .gst{
  position: relative;
      bottom:30px;
    margin:auto;
    width:150px;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
      line-height: 22.5px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: white;
      border-radius: 5rem;
      border: 1px solid white;
      background: #7c80f5;
      padding: 8px 4px;
  }
  .pricing-ai .btnsale{
    margin: 0;
      padding: 0;
      position: relative;
      bottom: -45px;
  }
    
  .pricing-ai .box{
      padding: 0 25px;
      border-left: 1px solid #ffffff38;
      margin: 0;	
  }
  .pricing-ai .text h5{
     font-family: Inter;
      font-size: 48px;
      color: white;
      font-weight: 600;
      line-height: 52px;
      border-radius: 5rem;
      padding: 0.6em;
      text-align: center;
      border: 1px solid white;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
     }
  .pricing-ai .heading{
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:white;
  padding: 3% 0;	
  }
  .pricing-ai img{
  width: 90%;
  height: 90%;
      object-fit: cover;
  }
  .pricing-btn{
  border-radius: 50px;
      background: linear-gradient(180deg, #8084E6 0%, #3036FD 100%);
      padding: 10px 20px;
      gap: 10px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: auto;
      display: flex;
      color: white;
      border: none;
  }
  .pricing-ai .text{
      display: grid;
      align-items: center;	
  }
  .tabs-ai h3{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.125px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  }
  .tabs-ai p{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.125px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  }
  .image-ai h3{
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  line-height: 66px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  width: 80%;
  margin: auto;	
  }
  .image-ai{
  padding: 5em 0;
      height: auto;
      align-items: center;
      display: grid;
  }
  .image-ai img{
    height:227px;
    padding: 0;
      margin: 0;
  }
  .image-ai .divimage{
    position:relative;
        padding-right: 8px;
      padding-left: 8px;
  }
  .image-ai h6{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.125px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  margin: 1% 0 5%;
  }
  .image-ai p{
     position: absolute;
      bottom: 30px;
      left: 30px;
      color: white;
      z-index: 99;
      font-family: Outfit;
      font-size: 20px;
      font-weight: 700;
      line-height: 25.2px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
  }
  .image-ai row{
  margin:0;	
  }
  .how-it-works .text2, .how-it-works .text1{
    font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.125px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  }
  .how-it-works .text3, .how-it-works .text4{
    font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.125px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  }
  .how-it-works .num2, .how-it-works .num1{
  font-family: Poppins;
  font-size: 50px;
  font-weight: 700;
  line-height: 75px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #00000040;
  }
  .how-it-works .num3, .how-it-works .num4{
  font-family: Poppins;
  font-size: 50px;
  font-weight: 700;
  line-height: 75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #00000040;
  }
  .how-it-works .img1{
  position: relative;
      z-index: 99;
      left: 125px;	
  }
  .how-it-works .img2{
  position: relative;
      z-index: 99;
      left: 10px;	
  }
  .how-it-works .img3{
  position: relative;
      z-index: 99;
      right: 175px;	
  }
  .how-it-works .img4{
      position: relative;
      z-index: 99;
      right: 65px;	
  }
  .how-it-works .textcontent1{
      position: relative;
      left: 85px;
      top: -20px;	
    text-align:right;
  }
  .how-it-works .textcontent2{
  position: relative;
      left: 20px;
      top: 20px;
      text-align: right;
    }
  .how-it-works .textcontent3{
  position: relative;
      left: -85px;
      top: -20px;
      text-align: left;	
  }
  .how-it-works .textcontent4{
        position: relative;
      left: -20px;
      top: 0px;
      text-align: left;
  }
  .tabs-ai .swiper-slide {
    text-align: center;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
  
  .tabs-ai .autoplay-progress {
   display:none;
  }
  
  .tabs-ai .tabs-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    gap:1em;
  }
  
  .tabs-ai .tabs-content {
    height: 100%;
    flex: 1 0 0%;
  }
  
  .tabs-ai .tabs-thumb {
    height: 100%;
    flex: 0 0 auto;
    width: 450px;
    box-sizing: border-box;
    padding: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .tabs-ai .tabs-thumb .swiper-slide {
    width: 20rem;
    height: auto;
    border: 0;
    outline: none;
    background:#FBF7FF;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 24px;
    text-align: left;
    border-radius: 1rem;
  }
  
  .tabs-ai .tabs-thumb-header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  /* .tabs-ai .tabs-slide-content img{
    width:100%;
    } */
    .tabs-ai .tabs-slide-content{
      text-align:center;
    }

    .tabs-ai .tabs-slide-content img{
      margin:0 auto;
    }
  /* .tabs-ai .tabs-thumb-avatar img{
    width:85%;
    height:85%;
  } */
  
  .tabs-ai .tabs-thumb h3 {
    margin: 0 0 0.3rem;
  }
  
  .tabs-ai .tabs-thumb .tabs-thumb-details p {
    margin: 0 0 0.5rem;
  }
  
  .tabs-ai .tabs-thumb .swiper-slide-thumb-active,
  .tabs-ai .tabs-thumb .swiper-slide:hover,
  .tabs-ai .tabs-content {
    color: #1d1c1a;
  }
  
  .tabs-ai .tabs-thumb .swiper-slide-thumb-active {
    opacity: 1;
    border-bottom: 2px solid #1489CC;
  }
  
  .tabs-thumb .swiper-slide-thumb-active .autoplay-progress svg {
    opacity: 1;
  }
  
  .tabs-ai .tabs-thumb-wrapper,
  .tabs-ai .tabs-thumb-wrapper .swiper-slide {
    width: 100%;
  }
  
  .tabs-ai .tabs-content{    
    background:#FBF7FF;
    border-radius:1rem;
  }

  .tabs-ai .tabs-content .swiper-wrapper {
    position: relative;
    z-index: 2;
  }
  
  .tabs-ai .tabs-content .tabs-slide-content {
    height:600px;
    display:flex;
    align-items:center;
    justify-content: center;
    width: 100%;
    /* background:#FBF7FF; */
    /* border-radius:1rem; */
    /* padding:3rem; */
  }
  
  .tabs-ai .tabs-content-circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 50%;
    z-index: 1;
    opacity: 0.3;
  }
  
  .tabs-ai .tabs-content-quote {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    height: 15rem;
    width: 15rem;
    z-index: 1;
    opacity: 0.5;
  }
  
  .tabs-ai .tabs-content-circle svg,
  .tabs-ai .tabs-content-quote svg {
    height: 100%;
    width: 100%;
  }
  
  .pricing-ai h6{
  font-family: Poppins;
      font-size: 23px;
      font-weight: 600;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: white;
      margin: 0;
      padding: 0;	
  }
  .pricing-ai .text .date{
      font-size: 58px;
  }
  .how-it-works .work{
    position:relative;
    top:75px;
  }
  .work h4{
  font-family: Poppins;
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 0.125px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
   color: black;	
  }
  .work p{
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: 0.125px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
     color: black;
  }
  .work .play{
    display:flex;
    margin:auto;
  }
  .work .part2{
  background: #F5F5FA;
      border: 2px dashed #2E3192;
      border-radius: 50%;
        padding: 13% 5%;
       margin: auto;
  }
  @media (max-width: 992px) {
   
    .pricing-ai .box{
      border-left: none;
    }
  .video-ai video {
    margin:auto;
    width: 300px;
     position: relative;
     display:flex;
  }
  /* .video-ai .show {
      position: absolute;
      top: 55px;
      left: 35px;
  } */
  .video-ai iframe{
    width: 90%;
    height: 300px;
    margin: 0 auto;
  }
  .tabs-ai{
    display:inline;
  }
  .banner-ai .heading, .ai-face-sync .heading, .how-it-works .heading, .work h4 {
      font-size: 34px;
     line-height: 50px;
     width:100%;
  }
  .banner-ai .para, .image-ai h6, .ai-face-sync .para, .work p {
      font-size: 16px;
      line-height: 30px;
    width:100%;
  }
  .video-ai h3, .image-ai h3 {
      font-size: 30px;
      line-height: 50px;
    width:100%;
   }
   .tabs-ai .heading, .pricing-ai .heading {
      font-size: 30px;
      line-height: 50px; 
    margin-top: 50px;
    text-align: center;
   }
   /* .pricing-ai .month, .pricing-ai .gst {
      position: relative;
      left: 90px;
      
  } */
  .tabs-ai h3{
    font-size: 16px;
  }
  .tabs-ai p{
    font-size: 14px;
  }
  .tabs-ai .tabs-thumb-header img{
    width: 90%;
    height: 90%;
  }
  .pricing-ai .box{
        margin: 0;
      padding: 0;
  }
  .pricing-ai img {
    width:200px;
    margin: 0 auto;
    /* height:100%; */
  }
  .video-ai .righttop {
      position: absolute;
      right: 75px;
      top: -35px;
      width: 20%;
  }
  .video-ai .bottomleft {
      position: absolute;
      left: 75px;
      bottom: -35px;
      width: 20%;
  }
  /* .pricing-ai .box .month {
      position: relative;
     left: 70px;
  }
  .pricing-ai .box .gst {
      position: relative;
      left: 70px;
  } */
  .tabs-ai .tabs-wrapper {
      display: inline;
  }
  .tabs-ai .tabs-thumb {
    width:100%;
  }
  .tabs-ai .tabs-content .tabs-slide-content {
  height:400px;
  margin-bottom:10px;
  }
  .work .part2 {
    padding:10%;
  }
  .how-it-works .textcontent1, .how-it-works .textcontent2, .how-it-works .textcontent3, .how-it-works .textcontent4, .how-it-works .img1, .how-it-works .img2, .how-it-works .img3, .how-it-works .img4{
    left:0;
    top:0;
    right:0;
    bottom:0;
    position:relative;
    text-align:left;
    display:inline!important;
  }
  .how-it-works .part2, .how-it-works .part3 {
          margin-top: 50px;
  }
  }

/* AI Face Sync Page Css End */




.offerings {
  background-color: #f5f5fa;
}
.book-photographer-tabs {
  text-align: center;
  justify-content: center;
  border: none;
}
.sub_heading {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 0.125px;
  text-align: center;
  color: black;
}
.sub_text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.125px;
  text-align: center;
  color: black;
  margin: auto;
  width: 75%;
}
.badgetrow {
  justify-content: space-evenly;
}
.badgets {
  border: 1px solid #2f3293;
  border-radius: 10px;
  width: 225px;
  position: relative;
}
.badgettext {
  font-size: 16px;
  line-height: 26px;
  bottom: 10px;
  position: relative;
}
.badgetnum {
  top: -7px;
  position: absolute;
  right: -7px;
  background: #2f3293;
  padding: 13px 10px 13px 20px;
  border-radius: 0px 5px 0px 50px;
  color: white;
}
.experts img {
  width: 95% !important;
  height: 200px;
}

.expert-item {
  display: grid;
  align-content: space-around;
  justify-content: space-between;
  align-items: flex-start;
  justify-items: center;
}
.sliderflipbox {
  margin: auto;
  justify-content: center;
}
.flip-head {
  font-size: 20px;
  line-height: 20px;
  font-family: Poppins;
  font-weight: 600;
}
.flip-text {
  font-size: 14px;
  line-height: 20px;
  font-family: Poppins;
  font-weight: 400;
}
.square-flip {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  float: left;
}
.square-flip {
  width: 225px;
  height: 225px;
}
.square,
.square2 {
  width: 100%;
  height: 100%;
}
.square {
  text-align: center;
  background-size: cover;
  background-position: center center;
  -ms-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.square-flip .square {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  z-index: 1;
}
.square-flip:hover .square {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  transform-style: preserve-3d;
}
.square2 {
  background-size: cover;
  background-position: center center;
  -ms-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.square-flip .square2 {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  z-index: 1;
}
.square-flip:hover .square2 {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  transform-style: preserve-3d;
}
.square-container {
  padding: 40px;
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transform: translateY(-50%) translateX(0px) scale(1);
  -ms-transform: translateY(-50%) translateX(0px) scale(1);
  transform: translateY(-50%) translateX(0px) scale(1);
  transform-style: preserve-3d;
  z-index: 2;
}
.square-flip:hover .square-container {
  -webkit-transform: translateY(-50%) translateX(-650px) scale(0.88);
  -ms-transform: translateY(-50%) translateX(-650px) scale(0.88);
  transform: translateY(-50%) translateX(-650px) scale(0.88);
  transform-style: preserve-3d;
}
.square-flip:hover .square-container2 {
  -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  -ms-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  transform-style: preserve-3d;
}
.name {
  color: black;
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
}
.location {
  color: black;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.flipbox-containerback {
  background: #2f3293;
  border-radius: 60px 20px;
  text-align: right;
  color: white;
  padding: 20px 20px 10px 20px;
  height: 220px;
}
.square-flip .square .boxshadow,
.square-flip .square .textshadow,
.square-flip .square2 .boxshadow,
.square-flip .square2 .textshadow {
  -ms-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition: 0.6s;
}
.square-flip .square .boxshadow {
  border-radius: 60px 20px;
  -webkit-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 13px 13px 0px 0px rgb(47 50 147);
  position: relative;
  left: -8px;

}
.square-flip .square .textshadow {
  -webkit-text-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  -moz-text-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  text-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
}
.square-flip:hover .square .boxshadow,
.square-flip:hover .square .textshadow {
  -webkit-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  -moz-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
}
.square-flip .square2 .boxshadow {
  -webkit-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  -moz-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
}
.square-flip .square2 .textshadow {
  -webkit-text-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  -moz-text-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  text-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
}
.square-flip:hover .square2 .boxshadow,
.square-flip:hover .square2 .textshadow {
  -webkit-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
}
#flip_icon {
  width: 40px !important;
  height: 40px;
  align-items: center;
}
.owl-nav {
  display: block !important;
}
.owl-prev {
  color: black !important;
  background: #ffffff !important;
  width: 35px !important;
  height: 38px !important;
  margin: 0 !important;
  border-radius: 50px !important;
  top: -215px !important;
  left: -5px !important;
  position: relative !important;
  float: left;
}
.owl-prev span {
  display: none;
}
.owl-prev:before {
  content: "\2190";
  font-size: 24px;
  font-weight: bold;
}
.owl-next span {
  display: none;
}
.owl-next:before {
  content: "\2192";
  font-size: 24px;
  font-weight: bold;
}
.owl-next {
  color: black !important;
  background: #ffffff !important;
  width: 35px !important;
  height: 38px !important;
  margin: 0 !important;
  border-radius: 50px !important;
  top: -219px !important;
  left: 30px !important;
  position: relative !important;
  float: right;
}
.fixed-pricing {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 6px -3px 0px 0px rgb(255 255 255 / 90%);
  border: 1px solid #f8f8f8;
  font-weight: bold;
  margin: 0px 15px;
}

@media (min-width: 576px) {
  .custom_container {
    max-width: 540px;
    margin: auto;
  }
}
@media only screen and (max-width: 768px) {
  .photography-banner .title {
    font-family: Poppins;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  .bannerbox{
    padding: 8px;
  }
  .name {
    padding-top: 0px !important;
  }
  .tab_content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #2e3192 !important;
    background: white;
    margin: 10px;
    width: 125px !important;
    height: 80px;
    gap: 5px;
    padding: 5px;
  }
  .tab_icon {
    width: 25px;
    height: 25px;
  }
  .photography-banner .text {
    font-size: 12px;
    margin: 0;
  }
  .photography-banner .objects {
    width: 32%;
    margin-right: 1px;
    margin-left: 1px;
  }
  .sub_text {
    width: 100%;
  }
  .form {
    position: relative;
    top: 0px;
    left: 0px;
  }
  .tab_content {
    width: 300px;
  }
  .sub_heading {
    font-size: 28px;
    line-height: 40px;
  }
  .book-photographer-tabs {
    justify-content: center;
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-left: 50px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .book-photographer-tabs::-webkit-scrollbar {
    display: none;
  }
  .tag p {
    font-size: 16px;
    line-height: 48px;
    padding: 10px;
    text-align: center;
  }
  .bannerbox {
    padding: 2em;
  }
  .collection2 {
    order: 1 !important;
  }
  .collection1 {
    order: 2 !important;
  }
  .collection3 {
    order: 3 !important;
  }
}
@media only screen and (max-width: 1024px) {
  .form {
    padding: 0px 10px;
    top: 0px;
  }
  .owl-nav {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .photography-banner{
    background-image: url(../img/photographer_banner_2.png);
    background-repeat: repeat-y;
    background-size: 755px;
  }
}



@media (max-width: 1280px) {
  .imagesize2{
    height: 332px;
  }
}

@media (max-width: 1200px) {
  .bannerbox{
    padding-right: 0;
  }
  .form {
    padding: 0px 20px 0px 32px;
    top: 10px;
  }
  .tag p{
    text-align: center;
  }
  .imagesize2 {
    height: 315px;
}
}

@media (max-width: 991px) {
  .bannerbox{
    padding: 15px;
  }
  .collection2 {
    order: 1 !important;
  }
  .imagesize1, .imagesize2, .imagesize4{
    height: 244px;
  }

}

@media (min-width: 1400px) {
  .custom_container {
    max-width: 1120px;
    margin: auto;
  }
}

@media (max-width: 1400px) {
  .pricing-ai .month, .pricing-ai .gst{
    font-size: 12px;
    width: 115px;
  }

  .pricing-ai .text .date{
    font-size: 58px;
  }
  .pricing-ai .text h5{
    font-size: 45px;
  }
}

.bs-tooltip-left .tooltip-inner{
  background-color: #686bf5;
  color: #fff;
  margin-right: 16px;
  margin-top: 12px;
  font-weight: 600;
  position: relative;
  box-shadow: 2px 2px 15px 3px rgba(46, 49, 146, .2);
}

.bs-tooltip-left .tooltip-inner:after{
  content: '';
  border-left: 6px solid #686bf5;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  position: absolute;
  right: -6px;
  top: 50%;
  margin-top: -6px;
}

@media (min-width: 479px) {
  .expert-item {
    justify-content: center;
  }
}


/* .accordion-button:not(.collapsed),
.accordion-button:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.accordion-button::after {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #2e3192;
  background-image: none !important;
}
.accordion-button.collapsed::after {
  background-color: gray;
} */

.main-headding{
  font-size: 42px;
    font-weight: 600;
}


.accordion-button{
  font-weight: 600;
}