/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #333 !important;
}

body {
  color: #212529;
}
.h1,
h1 {
  font-size: 1.3884rem;
}
.h2,
h2 {
  font-size: 1.23435rem;
}
.h3,
h3 {
  font-size: 1.096875rem;
}
.h4,
h4 {
  font-size: 0.999375rem;
}
.h5,
h5 {
  font-size: 0.975rem;
}
.h6,
h6 {
  font-size: 0.866775rem;
}

small {
  font-size: 80%;
}

.btn-block {
  width: 100%;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #252775;
  border-color: #22246b;
}

.rounded {
  border-radius: 0.25rem !important;
}

.text-muted {
  color: #6c757d !important;
  font-weight: 500;
}

.fw-500 {
  font-weight: 500;
}

a {
  text-decoration: none;
  color: #2e3192;
}
.py-90 {
  padding: 90px 0;
}
.py-60 {
  padding: 60px 0;
}
.pb-90 {
  padding-bottom: 90px;
}
.rounded {
  border-radius: 0.25rem !important;
}
.search {
  background: #2e3192;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}

.form-label {
  font-weight: 500;
}

.text1head h3 {
  text-align: center;
  color: white;
  font-family: "Poppins", system-ui;
  font-size: 45px;
  font-weight: 600;
  line-height: 67.5px;
  text-shadow: 4px 4px 20px rgba(0, 0, 0, 0.6);
  margin-bottom: 35px;
}
#overlay {
  height: 100%;
  /* background-image: linear-gradient(black, transparent); */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 2.47%,
    rgba(0, 0, 0, 0.2) 23.92%,
    rgba(0, 0, 0, 0) 47.32%,
    #000000f5 100%
  );
}

.fa-chevron-down {
  position: relative;
  top: 2px;
  color: black;
}

fa-headphones:before {
  content: "\f025" !important;
}
.wrapper .search_box .dropdown:before {
  display: none;
}
.headerbg {
  background-image: url(../img_new/hbg.png);
  background-size: contain;
  padding: 10px 0px;
}

.space {
  position: relative;
  top: 100px;
  margin-bottom: 100px;
}
.test1h3:before {
  background: url(../img_new/dots.png);
}
.circle {
  padding: 10px;
  color: white;
  font-size: 20px;
}
.announcement-bar {
  background-color: #5f63ff;
  color: white;
  text-align: center;
  min-height: 27px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-more,
.show-more:hover {
  background: rgb(46, 49, 146);
  border: none;
  color: white !important;
  cursor: pointer;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
  font-size: 12px;
  margin: 0px;
  width: auto;
  border-radius: 4px;
  margin-right: 10px;
  float: right;
}

button.show-more:hover {
  color: #fff !important;
  background: rgb(46, 49, 146, 0.95);
}

.button-bar {
  text-align: right;
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 9;
}
.stickybtn {
  border: none;
  font-weight: bold;
  font-size: 16px;
  padding: 15px;
  border-radius: 50px 0px 50px 50px;
  border: 1px solid #fff;
}
.coll {
  font-family: "Inter", system-ui;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  left: 20px;
  bottom: 26px;
  margin-right: 20px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 4px;
  display: none !important;
}

.coll1 {
  font-family: "Inter", system-ui;
  font-weight: bold;
  position: absolute;
  right: 50px;
  bottom: 10px;
  background: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.foot {
  margin-right: 50px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 40px;
}

.collections-block {
  background: url(../img_new/collections_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}
.btnexp {
  background: linear-gradient(180deg, #ffffff1c, #ffffff80, #ffffff, #ffffff);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  left: 0;
  padding: 20px;
  text-align: center;
}
.texthigh {
  bottom: 10px;
  position: absolute;
  color: white;
  padding: 20px;
  font-size: 16px;
}
.copy {
  position: absolute;
  right: 10px;
  margin: 10px 0px 0px 0px;
  padding: 10px;
  z-index: 1;
}

.more-icon {
  position: absolute;
  right: 20px;
  margin: 10px 10px 0px 0px;
}

.subdiv {
  position: relative;
}
.banner {
  background-image: url(../img_new/banner.png);
  height: 550px;
  background-size: cover;
  background-repeat: no-repeat;
}
.stickybtn {
  background-color: #2e3192;
  color: white;
}
.wrapper {
  width: 760px;
  margin: auto;
  /* position: relative;
  top: 100px; */
}

.wrapper.small {
  width: 350px;
  padding-left: 12px;
}

/* .wrapper-inner {
  width: 700px;
  margin: auto;
  position: relative;
  top: 100px;
} */

.wrapper .search_box {
  background: #fff;
  border-radius: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 9px;
  box-shadow: 0 0px 19px 0px #0000000f;
  /* box-shadow: 0 8px 6px -10px #b3c6ff; */
}

.wrapper .search_box .dropdown {
  color: #9fa3b1;
  cursor: pointer;
  text-wrap: nowrap;
  border-radius: 25px;
  padding: 6px 14px;
  font-size: 14px;
  color: black;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .search_box .dropdown .default_option {
  margin-right: 12px;
}

.wrapper .search_box .dropdown ul {
  position: absolute;
  top: 50px;
  right: 0;
  background: #fff;
  width: 150px;
  border-radius: 5px;
  padding: 20px;
  display: none;
  box-shadow: 8px 8px 6px -10px #b3c6ff;
}

.wrapper .search_box .dropdown ul.active {
  display: block;
  color: black;
}

.wrapper .search_box .dropdown ul li {
  padding-bottom: 12px;
}

.wrapper .search_box .dropdown ul li:last-child {
  padding-bottom: 0;
}

.wrapper .search_box .dropdown ul li:hover {
  color: #6f768d;
}

.wrapper .search_box .dropdown:before {
  content: "";
  position: absolute;
  top: 18px;
  right: 20px;
  border: 8px solid;
  border-color: black transparent transparent transparent;
}

.wrapper .search_box .search_field {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.wrapper .search_box .search_field .input:focus {
  outline: none;
}

.sticky-header.scrolled .wrapper .search_box {
  padding: 4px;
}

.photography-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 4px;
}
.photography-grid .stock-img-col {
  -ms-flex: 20%;
  flex: 20%;
  max-width: 20%;
  padding: 0 4px;
}

.photography-grid .stock-img-col .subdiv {
  border-radius: 8px !important;
  overflow: hidden;
  margin-bottom: 8px;
}

.photography-grid .col1 .subdiv:first-child,
.photography-grid .col3 .subdiv:nth-child(2),
.photography-grid .col5 .subdiv:nth-child(2) {
  height: 250px;
}
.photography-grid .col1 .subdiv:nth-child(2),
.photography-grid .col3 .subdiv:nth-child(3),
.photography-grid .col5 .subdiv:nth-child(1) {
  height: 368px;
}

.photography-grid .col1 .subdiv .subdiv:first-child,
.photography-grid .col1 .subdiv .subdiv:nth-child(2),
.photography-grid .col3 .subdiv .subdiv:first-child,
.photography-grid .col3 .subdiv .subdiv:nth-child(2),
.photography-grid .col5 .subdiv .subdiv:first-child,
.photography-grid .col5 .subdiv .subdiv:nth-child(2) {
  height: 180px !important;
}

.photography-grid .col4 .subdiv:nth-child(1),
.photography-grid .col4 .subdiv:nth-child(2),
.photography-grid .col4 .subdiv:nth-child(3) {
  height: 274px;
}

.photography-grid .col2 .subdiv:first-child,
.photography-grid .col2 .subdiv:last-child {
  height: 403px;
  overflow: hidden;
  margin-bottom: 8px;
}

.stock-img-col img {
  margin-bottom: 8px;
  vertical-align: middle;
  width: 100%;
  border-radius: 8px;

  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}
.wrapper .search_box .search_field .input {
  height: 100%;
  border: 0px;
  color: #6f768d;
  width: calc(100% - 50px);
  margin-left: 10px;
  font-size: 15px;
}

.wrapper .search_box .search_field .fas {
  float: left;
  position: relative;
  padding-left: 10px;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: #5078ef;
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: #9fa3b1;
}
::-moz-placeholder {
  color: #9fa3b1;
}
:-ms-input-placeholder {
  color: #9fa3b1;
}

.head {
  display: flex;
  width: 570px;
  height: 260px;
}
.head img {
  margin: 0px 15px;
}
.headh3 {
  font-family: "Poppins", system-ui;
  font-weight: 600;
  font-size: 45px;
  line-height: 64px;
  margin-left: 10px;
}

.headp {
  font-family: "Inter", system-ui;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  margin-right: 70px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.main-title {
  font-family: "Poppins", system-ui;
  font-weight: 600;
  font-size: 45px;
  line-height: 60px;
  text-align: Center;
  margin: 0 0 50px 0px;
}
.handpicked-blog .img-card {
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.closeRight{
  justify-content: space-between !important;
}
/* .handpicked-blog .img-card .img-hover:hover {
  animation: slide 8s linear infinite forwards;
} */

.handpicked-blog .img-card:hover .img-hover {
  animation: slide 8s linear infinite forwards;
}

/* .handpicked-blog .img-card.collection1, .handpicked-blog .img-card.collection2, .handpicked-blog .img-card.collection4, .handpicked-blog .img-card.collection5{
  height: 269px;
}
.handpicked-blog .img-card.collection3, .handpicked-blog .img-card.collection6{
  height: 352px;
}

.handpicked-blog .img-card.collection1 .img-hover img, .handpicked-blog .img-card.collection2 .img-hover img, .handpicked-blog .img-card.collection4 .img-hover img, .handpicked-blog .img-card.collection5 .img-hover img{
  width: 320px !important;
  height: 100% !important;
} */

.handpicked-blog .img-card .img-hover {
  display: flex;
  width: 750px;
}

.handpicked-blog .img-card.collection1 > div > div,
.handpicked-blog .img-card.collection2 > div > div,
.handpicked-blog .img-card.collection4 > div > div,
.handpicked-blog .img-card.collection5 > div > div {
  width: 250px !important;
  height: 269px;
}

.handpicked-blog .img-card.collection3 > div > div,
.handpicked-blog .img-card.collection6 > div > div {
  width: 250px !important;
  height: 352px;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-450px, 0, 0);
  }
  100% {
    transform: translate3d(0px, 0, 0);
  }
}
.handpicked-blog .img-card:after {
  content: "";
  background-image: linear-gradient(to bottom, #ff000000, #000000ad);
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  border-radius: 8px;
  height: 120px;
}

.handpicked-blog .texthigh {
  bottom: 14px;
  position: absolute;
  color: white;
  padding: 0 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  z-index: 2;
  margin-bottom: 0;
  align-items: center;
  text-transform: capitalize;
}
.handpicked-blog .texthigh span {
  font-weight: bolder;
  color: white;
  font-size: 31px;
}

.section1 {
  float: left;
  width: 50%;
  box-sizing: border-box;
}
.dots {
  position: absolute;
  z-index: -1;
  height: 150px;
}
.section1h3,
.test1h3 {
  font-family: "Poppins", system-ui;
  font-weight: 600;
  font-size: 45px;
  line-height: 65px;
  z-index: 2;
  position: relative;
  margin: 20px;
}
.section {
  margin-top: 100px;
}
.section1p,
.test1p {
  font-family: "Inter", system-ui;
  margin: 0px 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.section1msg {
  font-size: 16px;
  color: Red;
}
.sectpad {
  padding: 0px 50px;
}
.custom-btn {
  margin: 20px;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22.68px;
  text-align: Center;
  background: #2e3192;
  color: white;
  padding: 10px 30px;
  border-radius: 25px;
  border: none;
}
.photomain-title {
  font-family: "Poppins", system-ui;
  font-weight: 600;
  font-size: 40px;
  text-align: Center;
  line-height: 50px;
  margin-bottom: 50px;
}
.clientlogoh3 {
  font-family: "Inter", system-ui;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  text-align: Center;
  margin: 30px;
}
.logotext {
  margin-top: 16px;
}
a:focus,
a:hover {
  text-decoration: none;
}
.subfooter {
  background-color: #2e3192;
  padding: 10px 0px;
}

.footerbg {
  background-image: url(../img_new/footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px 30px 0px;
}
.footet-text {
  font-family: "Inter", system-ui;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  color: white !important;
  text-decoration: none;
}

.footerbg ul {
  padding: 0;
  margin: 0;
}

.social-icons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.social-icons a {
  background-color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.copyright {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.footerhead {
  font-family: "Inter", system-ui;
  font-weight: 600;
  font-size: 18px;
  color: white;
}
.spaceclientlogo {
  margin-top: 50px;
  justify-content: space-between;
}
.car {
  margin: 70px 0px 0px 0px;
}
.clientlogo {
  padding: 0px 0px 50px 0px;
}
.sectpad1 {
  padding: 50px 0px 50px 200px;
}
.testimonial-content-block {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
}
.col-lg-1 {
  padding: 0px;
}
.imgtext {
  position: relative;
  color: #ffffff;
  font-family: "Poppins", system-ui;
  font-weight: bold;
  left: 75px;
  top: -35px;
}
.imgtext1 {
  position: relative;
  color: #ffffff;
  font-family: "Poppins", system-ui;
  font-weight: bold;
  left: 65px;
  top: -35px;
}
.imgtext2 {
  position: relative;
  color: #ffffff;
  font-family: "Poppins", system-ui;
  font-weight: bold;
  left: 55px;
  top: -35px;
}

.owl-theme .owl-controls {
  margin-top: 30px;
  /* text-align: right; */
}

.testimonial-card {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(125deg, #2e3192, #5d62fb);
  border-radius: 25px;
  margin: 10px;
  min-height: 350px;
}

.testimonial-card .pic {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 60px;
  left: 20px;
}
.testimonial-card .pic img {
  width: 100%;
  height: auto;
}
.testimonial-card .description {
  font-size: 15px;
  /* letter-spacing: 1px; */
  color: white;
  margin: 15px 0px;
  font-family: "Inter", system-ui;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.testimonials {
  position: relative;
  margin-bottom: 90px;
  overflow: hidden;
}

.testimonials .bg-column {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.testimonials .bg-column:before {
  position: absolute;
  content: "";
  background: #e3f6ff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  border-radius: 0 100px 100px 0;
}
.testimonials .user {
  border: 2px solid #fff;
  width: 70px;
  height: 70px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 50%;
}
.testimonials .name {
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  letter-spacing: 1px;
  color: white;
  margin: 0;
}
.testimonials .designation {
  display: inline-block;
  font-size: 14px;
  color: white;
  font-style: italic;
  font-family: "Inter", system-ui;
  margin-bottom: 0;
}
.owl-theme .owl-controls .owl-page span {
  border: 2px solid #2a3d7d;
  background: blue !important;
  border-radius: 0 !important;
  opacity: 1;
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls .owl-page:hover span {
  background: blue !important;
  border-color: blue;
}

.owl-item .block-1 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  grid-gap: 10px;
}

.owl-item .block-1 img {
  width: 130px;
  height: 130px;
  margin: 0 auto;
}

.owl-wrapper {
  width: 2880px;
}
.nav-menu ul {
  margin: 0px;
}
.navbar {
  align-items: center;
  justify-content: start;
  padding: 5px 50px;
  margin-bottom: 0px;
}
/* .profile-icon {
  position: absolute;
  right: 50px;
} */
.nav-menu ul li a:hover {
  text-decoration: none;
}
.sticky-header {
  position: fixed;
  top: 35px;
  width: 100%;
  color: #fff;
  z-index: 1021;
}

.sticky-header.scrolled {
  top: 0px;
  background: #2e3192;
  min-height: 46px;
}

.sticky-header.scrolled .wrapper {
  width: 350px;
  padding-left: 12px;
}

.sticky-header.scrolled .dropdown {
  display: flex !important;
}

.sticky-header.scrolled .custom-dropdown-menu {
  top: 50px;
}
.inner-nav.sticky-header {
  position: relative;
  top: 0;
  width: 100%;
  color: #fff;
  z-index: 1021;
}

.inner-nav.sticky-header .icons {
  color: rgba(0, 0, 0, 0.65);
}
.inner-nav.sticky-header .profile-dropdown > a {
  border-right: 1px solid rgba(0, 0, 0, 0.4);
}
.inner-nav.sticky-header .profile-dropdown > a.pricing-tab {
  border: 1px solid rgb(46 49 146);
  color: #2e3192;
}
.inner-nav.sticky-header .profile-dropdown > a.pricing-tab:hover {
  color: #fff;
}
.inner-nav.sticky-header.scrolled .profile-dropdown > a {
  border-right: 1px solid rgba(250, 250, 250, 0.4);
}
.inner-nav.sticky-header.scrolled .profile-dropdown > a.pricing-tab {
  border: 1px solid #fff;
  color: #fff;
}

.inner-nav.sticky-header.scrolled {
  position: fixed;
  top: 0px;
  background: #2e3192;
  min-height: 42px;
}

.inner-nav .collection2 svg path {
  fill: #333;
}

.inner-nav.scrolled .collection2 svg path {
  fill: #fff;
}

.inner-nav.sticky-header.scrolled .picxy-nav {
  background-color: #2e3192 !important;
}

.inner-nav.sticky-header.scrolled .picxy-nav .user-logo-svg {
  border: 2px solid #fff;
  border-radius: 50%;
}

.inner-nav.sticky-header.scrolled .picxy-nav .nav-link {
  color: #fff;
}

.wizard-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  top: 4px;
}
.wizard-icon .fa {
  font-size: 12px;
  position: relative;
  top: 1px;
}

@media (min-width: 1600px) {
  .inner-testimonial {
    padding: 50px 154px 50px 308px;
  }
}

@media (max-width: 1400px) {
  .picxy-nav a {
    font-size: 15px important;
  }
  .User-logo {
    width: 35px;
  }
  .navbar {
    padding: 1px 6px !important;
  }
  .sticky-header.scrolled .custom-dropdown-menu {
    top: 45px;
  }
  .picxy-nav a {
    font-size: 15px;
  }
  .picxy-nav a.nav-link {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .wrapper {
    width: 530px;
  }
  .wrapper .search_box {
    padding: 4px;
  }
  .sticky-header.scrolled .search {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
  .sticky-header.scrolled .wrapper {
    width: 350px;
  }
  .sticky-header.scrolled .wrapper .search_box .dropdown {
    font-size: 13px;
    padding: 2px 10px;
  }

  .sticky-header.scrolled .wrapper .search_box .search_field .input,
  .wrapper .search_box .dropdown .default_option {
    font-size: 13px;
  }

  .sticky-header.scrolled .wrapper .search_box .dropdown .default_option {
    font-size: 13px;
    padding: 0;
    background: transparent;
  }
  .main-title,
  .headh3,
  .clientlogoh3,
  .section1h3,
  .test1h3,
  .text1head h3,
  .display-1.white {
    font-weight: 600;
    font-size: 30px !important;
    line-height: 46px;
  }
  .display-1.white {
    margin: 10px !important;
    line-height: 36px !important;
  }
  .headp {
    font-size: 15px;
    line-height: 25px;
  }
  .collections-block {
    padding-top: 30px;
  }
  .handpicked-blog .img-card.collection3 > div > div,
  .handpicked-blog .img-card.collection6 > div > div {
    width: 250px !important;
    height: 269px;
  }
  .sectpad1 {
    padding: 20px 0px 20px 50px;
  }
  .client-logos {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto !important;
    grid-gap: 10px;
  }
  .sectpad1 {
    padding: 50px 0px 50px 120px;
  }
  .owl-item .block-1 {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 10px;
  }

  .owl-item .block-1 img {
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .sticky-header.scrolled .wrapper {
    width: 350px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .main-title,
  .headh3,
  .clientlogoh3,
  .section1h3,
  .test1h3 {
    line-height: 42px;
  }
  .headp {
    line-height: 22px;
  }
  .testimonials .bg-column {
    width: 80%;
  }
  /* .sticky-header.scrolled .dropdown {
    display: none !important;
  } */
}
@media (max-width: 1024px) {
  .stock-img-col {
    max-width: 100%;
    flex: 100%;
  }
}

@media (max-width: 991px) {
  .section {
    margin-top: 20px;
  }
  /* .cart-icon{
    position: absolute !important;
    right: 74px;
    top: 9px;
  } */
  .cart-icon div {
    border: 1px solid #fff;
    border-radius: 5px;
    height: 32px;
    width: 32px;
  }
  .cart-icon div svg {
    position: relative;
    left: 4px !important;
    top: 2px;
  }
  .cart-icon > div svg path,
  .inner-nav.scrolled .cart-icon > div svg path {
    fill: #fff !important;
  }

  .cart-counter {
    right: -6px !important;
  }

  .inner-nav .cart-icon div {
    border: 1px solid #d9d9d9;
  }

  .inner-nav .cart-icon div svg path {
    fill: #2e3236 !important;
  }

  .cart-icon svg {
    position: relative;
    left: 4px;
  }

  .inner-nav.sticky-header.scrolled,
  .sticky-header.scrolled {
    min-height: 50px;
  }
  .wrapper.small {
    width: 400px;
    padding-left: 0;
  }
  button.navbar-toggler.mobi-search {
    right: 84px !important;
    top: 0px !important ;
  }
  .li-inner-nav {
    list-style: none;
  }
  .custom-dropdown-menu.show,
  .collection2 svg,
  .User-logo {
    display: none !important;
  }
  .button-bar {
    zoom: 0.8;
  }

  .pricing-tab:hover,
  .inner-nav.sticky-header.scrolled .profile-dropdown > a.pricing-tab {
    background: transparent !important;
    color: #212529 !important;
  }

  .profile-icon {
    width: 100%;
    justify-content: space-between;
  }

  .inner-nav.sticky-header .profile-dropdown > a {
    border: 0;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .form {
    padding: 0px 15px !important;
  }

  .tab_content {
    margin: 5px 2px !important;
  }

  .footerhead {
    margin-bottom: 10px !important;
  }

  .footerbg .row > [class*=" col-"] {
    margin-bottom: 10px;
  }

  .list-scroll-mobi {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    gap: 3px;
    justify-content: center;
    min-height: 30px;
  }

  .list-scroll-mobi .list-inline-item {
    text-wrap: nowrap;
    margin: 0;
  }

  .inner-nav.sticky-header.scrolled .navbar-toggler.mobi-search svg path {
    fill: white !important;
  }

  .photo-wrapper {
    min-height: 100px !important;
  }

  .photography-grid .col2 .subdiv:first-child,
  .photography-grid .col2 .subdiv:last-child {
    height: 259px;
  }

  .photography-grid .col1 .subdiv .subdiv:first-child,
  .photography-grid .col1 .subdiv .subdiv:nth-child(2),
  .photography-grid .col3 .subdiv .subdiv:first-child,
  .photography-grid .col3 .subdiv .subdiv:nth-child(2),
  .photography-grid .col5 .subdiv .subdiv:first-child,
  .photography-grid .col5 .subdiv .subdiv:nth-child(2) {
    height: 100px !important;
  }

  .photography-grid .col1 .subdiv:first-child,
  .photography-grid .col3 .subdiv:nth-child(2),
  .photography-grid .col5 .subdiv:nth-child(2) {
    height: 175px;
  }

  .photography-grid .col1 .subdiv:nth-child(2),
  .photography-grid .col3 .subdiv:nth-child(3),
  .photography-grid .col5 .subdiv:nth-child(1) {
    height: 213px;
  }

  .photography-grid .col4 .subdiv:nth-child(1),
  .photography-grid .col4 .subdiv:nth-child(2),
  .photography-grid .col4 .subdiv:nth-child(3) {
    height: 167px;
  }

  /* .profile-name{
    position: absolute;
    top: -38px;
    color: #fff !important;
    right: 118px;
  }

  .cart-icon{
    position: absolute !important;
    top: -36px !important;
    right: 70px !important;
  }

  .cart-icon svg path{
    fill: white !important;
  } */
}

@media only screen and (max-width: 767px) {
  .sticky-header.scrolled {
    min-height: 50px;
  }
  .photography-grid .stock-img-col {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding: 0 4px;
  }
  .owl-wrapper {
    width: 1140px;
  }
  .testimonial {
    padding: 20px;
    text-align: center;
  }
  .testimonial .pic {
    display: block;
    position: static;
    margin: 0 auto 15px;
  }
  .testimonials .bg-column {
    width: 100%;
  }
  #overlay {
    overflow: hidden;
  }
  .banner {
    height: 320px !important;
  }

  .foot {
    margin: 0px;
  }
  .wrapper .search_box {
    padding: 0px;
  }
  .wrapper .search_box .dropdown {
    margin-left: 0px;
  }
  .wrapper .search_box .search_field .input {
    margin-left: 15px;
  }
  .wrapper .search_box .dropdown .default_option {
    font-size: 11px;
  }
  .wrapper .search_box .dropdown:before {
    top: 15px;
    right: 10px;
  }
  .dots {
    display: none;
  }
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
  .section1,
  .test1 {
    width: 100% !important;
  }
  .head {
    margin-right: auto;
    margin-left: auto;
    display: block;
    width: 100%;
    height: 100%;
  }
  .stock-img-col {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .wrapper {
    /* top: 250px;
    left: 5%; */
    width: 100%;
    min-width: 330px;
    margin: 0 auto;
  }
  .big-head {
    padding-top: 120px !important;
    padding-bottom: 80px !important;
  }
  .wrapper .search_box .search_field {
    top: 0px;
  }
  .text1head h3 {
    font-size: 25px;
    margin-bottom: 15px;
    line-height: 30px;
  }
  .collections-block {
    padding-top: 0;
    background-size: auto;
  }
  .car {
    margin: 0px;
  }
  .sectpad1 {
    padding: 20px;
  }

  .wrapper.small {
    width: 300px !important;
  }

  .wrapper .search_box {
    width: 350px;
    /* width:100%; */
  }
  .head img {
    margin: 0px;
  }
  .headp {
    margin: 0px 0px 25px 0px;
  }
  .sectpad {
    padding: 0px;
  }
  .col-lg-1 {
    width: 150px;
    height: 150px;
    margin: auto;
  }
  .clientlogoh3 {
    margin: 0px;
    font-size: 23px !important;
    line-height: 36px;
  }
  .spaceclientlogo {
    margin: 0px;
  }
  .main-title {
    font-size: 22px;
    margin-top: 0px;
    line-height: 36px;
  }
  /* .button-bar {
    display: none;
  } */
  .headh3 {
    font-size: 26px;
    line-height: 36px;
    margin-left: 0px;
  }
  .section1h3,
  .test1h3 {
    font-size: 26px;
    line-height: 35px;
  }
  .section {
    margin: 0px;
  }
  .photomain-title {
    margin: 20px 0px;
    font-size: 26px;
    line-height: 36px;
  }
  .clientlogo {
    padding: 0px;
  }
  .search {
    position: relative;
    top: 0;
    left: 0px;
  }
  .wrapper .search_box .dropdown .default_option {
    padding: 10px 32px 10px 16px;
  }
  /* .wrapper .search_box .search_field {
    width: 250px;
    height: 100;
  } */

  .mobile-menu-toggle {
    display: block;
  }
  .sticky-header.scrolled {
    top: 0px;
    background: #2e3192;
  }
  .nav-menu {
    display: none;
  }
  /* .profile-icon {
    position: relative;
    left: 150px;
  } */
  .navbar {
    padding: 0px 30px;
  }
  .client-logos {
    display: grid;
    grid-template-columns: auto auto auto !important;
    grid-gap: 10px;
  }
  .owl-item .block-1 {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
  }
  .owl-item .block-1 img {
    width: 90px;
    height: 90px;
    margin: 0 auto;
  }
  .social-icons {
    justify-content: space-around;
    margin-top: 10px;
    border-top: 1px solid #4a4edb;
    padding-top: 12px;
  }
  .li-inner-nav {
    list-style: none;
  }
  .marquee__inner > div {
    width: 170px !important;
    height: 100px !important;
  }
}

@media (max-width: 480px) {
  .wrapper {
    width: 300px;
    margin: 0 auto;
  }
  .expert-item {
    justify-content: center !important;
  }
}
.wrapper .search_box {
  /* width: 350px; */
  width: 100%;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 30px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-family: "Outfit", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 20.16px;
  text-align: left;
}

.collection svg {
  display: none;
}

.pricing-tab {
  border: 1px solid #ffffff87;
  border-radius: 6px;
  margin-right: 18px;
  transition: all 0.5s;
}
.pricing-tab:hover {
  background: #2e3192;
}

.icons {
  display: flex;
  align-items: center;
}

.person-icon img {
  height: 24px;
  width: 24px;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle span {
  background-color: white;
  height: 3px;
  width: 25px;
  margin: 3px 0;
}

.custom-dropdown-menu {
  min-width: 800px;
}

.custom-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.custom-grid > a {
  /* flex: 1 1 auto;
  width: calc(25% - 1rem); */

  flex: 1 1 auto;
  width: calc(25% - 1rem);
  text-wrap: nowrap;
  line-height: 18px !important;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 768px) {
  .collection1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #333;
  }

  .nav-links.active {
    display: flex;
    font-size: 18px;
    line-height: 42px;
    padding: 25px 0px;
    background: #5f63ff96;
  }

  .menu-toggle {
    display: flex;
    float: right;
    position: relative;
    left: 175px;
  }

  .navbar-nav .nav-link {
    color: white;
  }

  /* ------------------------------------------------------------- */
  .default_option {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
  }

  .dropdown ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .dropdown.active ul {
    display: block;
  }

  .dropdown ul li {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
  }

  .dropdown ul li:hover {
    background-color: #f0f0f0;
  }

  .wrapper .search_box .dropdown ul li {
    padding: 0;
    border: 0;
  }
  /* ------------------------------------------------------------- */
}

.client-logos {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  grid-gap: 10px;
}

@media (min-width: 1500px) {
  .wrapper.small,
  .sticky-header.scrolled .wrapper {
    width: 500px;
    padding-left: 12px;
  }
}
@media (max-width: 1400px) {
  .banner {
    height: 400px;
  }
}

@media (min-width: 1401px) and (max-width: 1700px) {
  .banner {
    height: 450px;
  }
  .big-head {
    padding-top: 170px !important;
  }
}

.marquee {
  width: 100%;
  overflow: hidden;
}

.marquee__inner {
  height: 100%;
  width: fit-content;
  align-items: center;
  display: flex;
  position: relative;
  will-change: transform;
  gap: 20px;
}

.marquee__inner > div {
  width: 300px;
  height: 160px;
  position: relative;
  border-radius: 10px;
}

.marquee__inner > div::after {
  content: "";
  background-image: linear-gradient(to bottom, #ff000000, #000000ad);
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  border-radius: 8px;
  height: 120px;
}

.marquee__inner > div > p {
  position: absolute;
  bottom: 4px;
  width: 100%;
  z-index: 9999999;
  top: auto;
  left: 0;
  color: var(--white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.marquee1 .marquee__inner {
  animation: marquee1 40s linear infinite;
}

.marquee2 .marquee__inner {
  animation: marquee2 40s linear infinite;
}

.marquee__inner:hover {
  animation-play-state: paused;
}
@keyframes marquee1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1200px);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(-1200px);
  }
  100% {
    transform: translateX(0);
  }
}

.topped {
  min-height: 550px !important;
}

@media (min-width: 1000px) and (max-width: 1599px) {
  .user-logo-svg svg {
    zoom: 0.75;
  }
  .cart-counter {
    /* top: -10px; */
    right: 10px !important;
  }
}
/* 
@media (min-width: 1600px) and (max-width: 1800px){
  .user-logo-svg svg{
    zoom:0.7;
  }
} */

@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    zoom: 0.99;
  }
  .picxy-nav a {
    font-size: 16px !important;
  }
  .owl-item .block-1 img {
    width: 100px;
    height: 100px;
  }
  .owl-item .block-1 {
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }
  .topped {
    min-height: 450px !important;
  }
}

@media (-webkit-device-pixel-ratio: 1.5) {
  * {
    zoom: 0.98;
  }
  .picxy-nav a {
    font-size: 16px !important;
  }
  .owl-item .block-1 img {
    width: 130px !important;
    height: 130px !important;
  }
  .owl-item .block-1 {
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }
  .wrapper {
    width: 645px;
  }
  .main-title,
  .headh3,
  .clientlogoh3,
  .section1h3,
  .test1h3,
  .text1head h3,
  .display-1.white {
    font-weight: 600;
    font-size: 36px !important;
    line-height: 51px;
  }
  .text1head h3 {
    margin-bottom: 20px;
  }
  .wrapper .search_box .search_field .input {
    font-size: 14px;
  }
  .banner {
    height: 350px;
  }
  .big-head {
    padding-top: 139px !important;
  }
  .collections-block {
    padding-top: 60px;
  }
  .user-logo-svg svg {
    zoom: 0.7;
  }
  .topped {
    min-height: 300px !important;
  }
}

@media (-webkit-device-pixel-ratio: 1.75) {
  * {
    zoom: 0.98;
  }
  .picxy-nav a {
    font-size: 16px !important;
  }
  .owl-item .block-1 img {
    width: 100px !important;
    height: 100px !important;
  }
  .owl-item .block-1 {
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }
  .search {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
  .text1head h3 {
    margin-bottom: 20px;
  }
  .wrapper .search_box .search_field .input {
    font-size: 14px;
  }
  .banner {
    height: 310px;
  }
  .big-head {
    padding-top: 139px !important;
  }
  .user-logo-svg svg {
    zoom: 0.7;
  }
}


/* Card Animation */
.card-success, .card-warning, .card-danger, .card-info {
  max-width: 500px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  text-align: center;
  padding: 20px;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.8s ease-out forwards;
}

.card-success .card-title, .card-warning .card-title, .card-danger .card-title, .card-info .card-title{
  font-size: 25px;
}

.card-success{
  max-width: 600px;
}

/* Success Icon Animation */
.success-icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.success-icon, .warning-icon, .danger-icon, .info-icon {
  font-size: 40px;
  position: absolute;
  animation: bounce 1s ease-in-out infinite alternate;
}

.success-icon{  color: #28a745;}
.warning-icon{  color: #ffc107;}
.danger-icon{  color: #dc3545;}
.info-icon{  color: #17a2b8;}

/* Ripple Effect */
.ripple {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1.5px solid #5f63ff;
  animation: rippleEffect 1.5s linear infinite;
}

.ripple:nth-child(2) {
  animation-delay: 0.5s;
}

.ripple:nth-child(3) {
  animation-delay: 1s;
}

/* Button Styling */
.btn-back {
  background-color: #28a745;
  color: #fff;
  border-radius: 25px;
  padding: 10px 20px;
  transition: 0.3s;
}

.btn-back:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Keyframe Animations */
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(30px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes bounce {
  from {
      transform: translateY(0);
  }
  to {
      transform: translateY(-5px);
  }
}

@keyframes rippleEffect {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  100% {
      transform: scale(1.5);
      opacity: 0;
  }
}
