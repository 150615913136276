@media (-webkit-device-pixel-ratio: 1.5) {
  * {
    zoom: 0.98;
  }
  .picxy-nav a{
    font-size: 15px !important;
  }
}

@media (-webkit-device-pixel-ratio: 1.75) {

  .picxy-nav a{
    font-size: 14px !important;
  }
}


h1.logo {
  font-size: 1em;
  margin-bottom: 0;
}

.text-phtr {
  color: #ff542b !important;
}
.border-picxy {
  border-color: #2e3192 !important;
}
.border-phtr {
  border-color: #ff542b !important;
}

.pointer {
  cursor: pointer;
}
.font-size-small {
  font-size: 14px;
}
.cbtn {
  padding-bottom: 0;
  padding-top: 0;
}
.big-head {
  padding-top: 240px;
  padding-bottom: 100px;
}
.big-background {
  display: flow-root;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.big-mask {
  background-color: rgba(0, 0, 0, 0.45);
}
.picxy-toggler .navbar-toggler-icon, .inner-nav.sticky-header.scrolled .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.picxy-toggler.navbar-toggler, .inner-nav.sticky-header.scrolled .navbar-toggler {
  border-color: rgb(255, 255, 255);
}
.pxy-subtitle {
  font-size: 24px;
  margin-bottom: 0;
}
.head-pic-info {
  margin-top: 150px;
}
.inline-item {
  display: block;
  float: left;
}

.navbar-nav .dropdown-toggle::after {
  display: none;
}
.form-control::placeholder {
  color: #999;
}
.form-control {
  border-radius: 0.25rem !important;
}
.btn-primary {
  color: #fff;
  background-color: #2e3192;
  border-color: #2e3192;
}

h1.home-tag-line {
  font-size: 16px;
}

.icon-drop-shadow {
  -webkit-filter: drop-shadow(1px 1px 2px #777);
  filter: drop-shadow(1px 1px 2px #777);
  &:hover {
    -webkit-filter: drop-shadow(1px 1px 2px #777);
    filter: drop-shadow(1px 1px 2px #777);
  }
}
.display-5 {
  font-size: 28.11px;
}

a .cat-link-text,
a .cat-link-text:hover {
  text-decoration: none;
  color: #444;
  font-size: 18px;
}
a.no-effect {
  text-decoration: none;
}
.p-i-nav {
  font-size: 18px;
}
.photo-price {
  padding-left: 20px;
}

.image-holder {
  min-height: 270px;
  border-radius: 2px;
  background-color: #f3f3f3;
  background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
  -webkit-animation: placeholderShimmer 2s linear 0s infinite normal forwards;
  animation: placeholderShimmer 2s linear 0s infinite normal forwards;
}
.video-react .video-react-poster {
  background-color: #fff;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }
  50% {
    background-position: 500px 0;
  }
  100% {
    background-position: -500px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
  100% {
    background-position: -500px 0;
  }
}

.page-heading {
  font-size: 1.35rem;
  font-weight: 600;
}

.outline-option {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  color: #666;
  font-size: 14px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  border: 1px solid #ececec;
}
.outline-option:hover {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #4a90e2;
}
.outline-option-selected,
.outline-option-selected:focus {
  outline: none;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #4a90e2;
  color: #fff;
  background-color: #4a90e2;
}

section .sub-head {
  font-size: 18px;
}

.testimonial-wrapper {
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5rem !important;
}
.testimonial-wrapper .message {
  font-size: 1.4em;
  letter-spacing: 0.34px;
  line-height: 24px;
  text-align: center;
  min-height: 75px;
}
.testimonial-wrapper .author-name {
  text-align: center;
  color: #000000;
  font-size: 1.2em;
}
.testimonial-author {
  width: 25%;
  display: inline-block;
  float: left;
  opacity: 0.5;
}
.testimonial-author.active {
  opacity: 1;
}
.price-section {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5rem !important;
  border-radius: 9px;
  position: relative;
}
.price-section .price-header {
  font-size: 32px;
  font-weight: bold;
  height: 42px;
}
.price-section .price-bar {
  height: 7px;
  background-color: #00bc8d;
  border-radius: 4px;
}
.price-section a.price-button {
  position: absolute;
  bottom: -20px;
  font-size: 18px;
  font-weight: bold;
  background-color: #00bc8d;
  color: #fff;
  padding: 8px 30px;
  width: 170px;
  left: 50%;
  margin-left: -85px;
  text-decoration: none;
  border-radius: 25px;
}
.price-section a.price-button:hover {
  text-decoration: none;
}
.client-logo {
  opacity: 0.5;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
}
.client-logo img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  max-height: 80px;
  max-width: 200px;
}
.client-logo.active,
.client-logo:hover {
  opacity: 1;
}

.client-logo.active img,
.client-logo:hover img {
  -webkit-filter: none;
  filter: none;
}

.form-page-num {
  display: inline-block;
}
.pagi-n-input {
  width: 35px;
  border-radius: 2px;
  border: 1px solid #ddd;
}

nav li a {
  font-weight: 400;
}

.bg--dark {
  background: #252525;
}

.footer-title {
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: #373a3c;
}
footer li a {
  color: #55595c;
  font-size: 1em;
}
footer li {
  line-height: 2em !important;
}
/* footer li a:not(:hover) {
  opacity: 0.8;
} */
footer li a:hover {
  opacity: 1;
  text-decoration: none;
  color: #373a3c;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
}
.footer-copyright {
  opacity: 0.6;
  font-size: 0.9em;
}

.licence ol {
  list-style: lower-roman;
}
.metup-border {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.background-image {
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.image1 {
  background-image: url("https://storage.googleapis.com/picxystatic/cache/2018/7/19/d2d41c679b8be15bcc68c87f255b81ce.jpg");
}
.image2 {
  background-image: url("https://storage.googleapis.com/picxystatic/cache/2018/11/14/0079fc3c15abf59037af039ef2ad5121.jpg");
}
.feature-8 {
  padding: 2.78571429em 0 3.71428571em 0;
}
.feature-8 img {
  max-height: 14.85714286em;
  margin-bottom: 1.85714286em;
}
.checkmark {
  display: inline-block;
  position: relative;
  width: 1.23809524em;
  height: 1.23809524em;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  top: 3px;
}
.checkmark:before {
  content: "\2713";
  color: #fff;
  font-size: 11px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 2px;
}
.pricing-border {
  border: 1px solid #ddd;
  border-radius: 5px 5px 0px 0px;
  padding: 2.78571429em 2.78571429em 0 2.78571429em;
}
.pricing .btn:last-child {
  bottom: 0;
  left: 0;
  margin: 0;
}
.pricing .cost {
  font-size: 1.35em;
}
.price-size {
  font-size: 1.9em;
  color: #222;
}
.contactus li {
  margin: 10px;
}
.contactus li a {
  text-decoration: none;
}
.p-tabs a {
  color: #4a90e2;
}
.p-tabs a:hover {
  text-decoration: none !important;
  color: #222;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
}
.p-tab {
  display: inline-block;
  float: left;
  padding: 0.25rem 0.5rem;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px 0 0 6px;
  border: 1px solid #ddd;
  border-right: none;
  margin-bottom: 10px;
}
.p-tab:last-child {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #ddd;
}
.p-tabs a.active {
  color: #999;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.picxy-fav-holder {
  margin-top: 5px;
}
.picxy-fav-btn {
  cursor: pointer;
  color: #fff;
  font-size: 1.2em;
  margin: 10px 0px 0px 0px;
}
.photo-wrapper {
  position: relative;
  min-height: 172px;
  /* background-color: rgba(0, 0, 0, 0.2); */
  border-radius: 0.3rem !important;
}

.photo-wrapper a {
  min-height: 172px;
}

.collection-wrapper {
  position: relative;
  text-align: center;
  min-height: 172px;
  /* width: 244px; */
  /* background: transparent linear-gradient(90deg, #0D0E40ED 0%, #0D0E40D1 36%, #0D0E405E 77%, #0D0E4000 100%) 0% 0% no-repeat padding-box; */
}
.picxy-fav-holder {
  position: absolute;
  display: none;
  right: 15px;
  top: 5px;
}
.picxy-fav-holder.liked {
  display: block;
}
.picxy-fav-holder.liked .picxy-fav-btn {
  color: #dc3545;
}
.picxy-fav-btn.liked {
  color: #dc3545;
}
.picxy-like-icon {
  color: #dc3545;
}
.photo-wrapper:hover .picxy-fav-holder {
  display: block;
}
.collection-list-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.15s ease-in; */
  /* transform: matrix(0, -1, 1, 0, 0, 0); */
  background: transparent
    linear-gradient(
      to top,
      #0d0e40ed 0%,
      #0d0e40d1 25%,
      #0d0e405e 45%,
      #0d0e4000 75%
    )
    0% 0% no-repeat padding-box;
}
.collection-wrapper:hover .collection-list-mask {
  background-color: rgba(0, 0, 0, 0);
  text-shadow: 2px 4px 3px red;
}
.collection-list-name {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.picxy-nav a.nav-link:not(hover),
.picxy-nav a.dropdown-item:not(hover) {
}
.picxy-nav a.dropdown-item:active {
  background-color: #fff;
}
.picxy-nav a.nav-link:hover {
  opacity: 1;
}
.picxy-nav a {
  /* font-size: 0.857142857142857em; */
  font-family: "Outfit", sans-serif;
  line-height: 2.166666666666667em;
  font-size: 16px;
  font-weight: 500;
  /* letter-spacing: 0.5px; */
  transition: opacity 0.2s ease-in;
}
.dropdown-item:focus, .dropdown-item:hover{
  color: #333 !important;
}

.picxy-nav a.dropdown-toggle svg{
  margin-left: 8px;
}

.picxy-nav a.nav-link {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.picxy-nav.pure-nav a.nav-link:not(hover),
.picxy-nav.pure-nav a.dropdown-item:not(hover) {
  opacity: 1;
}
.picxy-nav.pure-nav a.nav-link {
  color: #fff;
}
.cart-icon {
  position: relative;
}

.cart-icon svg {
  position: relative;
  left: -10px;
}

.cart-counter {
  background-color: #ffc107;
  border-radius: 50%;
  color: #2e3192;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -7px;
  right: 11px;
  font-size: 12px;
  font-weight: 600;
}

.picxy-search-input {
  border-color: #dfe1e5 !important;
  border-right: none;
  border-radius: 20px;
}
.picxy-search-input input {
  font-size: 16px;

  color: #222;
  letter-spacing: 0.6px;
  border-color: #dfe1e5 !important;
  border-right: none;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}
.picxy-search-input input:focus {
  box-shadow: none;
}
.picxy-search-input .picxy-search-icon {
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
  color: #999;
  border-color: #dfe1e5 !important;
  border-left: none;
  height: 42px;
}
.picxy-search-input:focus,
.picxy-search-input:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}
.picxy-suggest-input:focus,
.picxy-suggest-input:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.picxy-nav .navpure-link {
  color: #fff;
}

.topped{
  min-height: 450px;
}

.topped .sticky-top {
  max-width: 700px;
  margin-bottom: 24px;
}

.autocomplete-dropdown-container {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

  position: absolute;
  z-index: 1030;
  width: 100%;
}
.autocomplete-dropdown-container .suggestion-item {
  padding-left: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 576px) {
  .price-size {
    font-size: 1.6em;
  }
}

@media (max-width: 991px) {

  .picxy-toggler.navbar-toggler, .inner-nav.sticky-header.scrolled .navbar-toggler, .navbar-toggler{
      zoom: 0.80;
      margin-top: 10px;
  }
  .mobile-cart {
    margin-top: 10px;
}
  button.navbar-toggler.mobi-search{
    zoom: 0.75 !important;
    margin-top: 12px !important;
  }
  .sticky-header.scrolled .main-logo{
    position: relative;
    top: 3px;
  }
  .picxy-fav-holder {
    display: block;
    text-align: right;
  }

  .user-logo-svg, .sticky-header.scrolled .dropdown{
    display: none !important;
  }


  .p-collapse {
    position: absolute;
    top:49px;
    right: 0;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    z-index: 1032;
    width: 100%;
    box-shadow: 0 10px 6px 4px rgba(32, 33, 36, 0.28);
    padding-top: 8px;
  }
  .container {
    max-width: 100%;
  }
  .dropdown-toggle {
    display: none;
  }
  .dropdown-menu {
    display: block !important;
    border: none;
    padding: 0 0;
    margin: 0 0 0;
  }

  .picxy-nav.pure-nav a.nav-link,
  .picxy-nav .li-inner-nav a.nav-link,
  .picxy-nav a.nav-link,
  .inner-nav.sticky-header.scrolled .picxy-nav .nav-link,
  .inner-nav.sticky-header .profile-dropdown > a.pricing-tab,
  .nav-link.pricing-tab {
    color: #212529;
  }

  .inner-nav.sticky-header .profile-dropdown > a.pricing-tab{
    border: 0;
  }

  .picxy-nav .navpure-link {
    color: #212529;
  }
}

.input-checkbox input:checked + label {
  border-color: #4a90e2;
  background: #4a90e2;
}
.input-checkbox input,
.input-radio input,
.input-select input {
  opacity: 0 !important;
  height: 2em !important;
  width: 2em !important;
  position: absolute !important;
  z-index: 999999;
}
.input-checkbox label {
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 6px;
  background: none;
  border: 1px solid #d3d3d3;
  position: relative;
}
.input-checkbox label:before {
  content: "";
  left: 0;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  opacity: 0;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.input-checkbox input:checked + label:after {
  content: "L";
  transform: rotateY(180deg) rotateZ(-45deg);
  color: #fff;
  position: absolute;
  width: 100%;
  left: -6px;
  top: -6px;
  font-weight: 700;
}
.event-img {
  height: 227px !important;
}
.btn:disabled {
  cursor: no-drop;
}
.google-logo {
  background: #fff;
  padding: 5px;
  margin-right: 5px;
}
.wallpaper-content {
  position: relative;
}
.wallpaper-download {
  position: absolute;
  top: 80%;
  bottom: 0;
  left: 0;
  right: 0;
  color: red;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.wallpaper-content:hover .wallpaper-download {
  visibility: visible;
  opacity: 1;
}

.wallpaper-content:hover img {
  opacity: 0.5;
}
@media (max-width: 768px) {
  .wallpaper-download {
    visibility: visible;
    opacity: 1;
    top: 80%;
  }
}

.banner-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.banner-mask {
  background-color: rgba(68, 68, 68, 0.2);
}

.banner-head {
  padding-top: 100px;
  padding-bottom: 100px;
}


@media (max-width: 545px) {
  .banner-background {
    background-size: contain;
  }

  .banner-head {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

@media (max-width: 575px) {
  .banner-mask {
    background-color: rgba(0, 0, 0, 0);
  }
}
.login .nav-tabs {
  margin-top: 3%;
  background: #fff;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  border: 2px solid #666;
}
.login .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-size: 1em;
  font-weight: 600;
  color: #666;
}
.login .nav-tabs .nav-item {
  width: 50%;
  margin-bottom: 0;
}
.login .nav-tabs .nav-link:hover {
  border: none;
}
.login .nav-tabs .nav-link.active {
  color: #fff;
  border: none;
  border-radius: 0px;
  background-color: #2e3192;
}
.login .nav-tabs .nav-link.active:hover {
  color: #fff;
}

.bulk_payment tbody td:nth-child(2) {
  font-weight: 600;
}
.italic-font-style {
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-18 {
  font-size: 18px !important;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  padding: 0.5rem 1rem;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.react-autosuggest__input--focused {
  outline: none;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
  font-weight: 400;
  font-size: 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 2;
  border-top: 0;
  overflow-y: auto;
  max-height: 35vh;
}
.alpha-text .react-autosuggest__suggestions-list::after {
  display: block;
  text-align: right;
  font-size: 14px;
  /* font-style: italic; */
  color: #777;
  content: "Alpha 1.0";
  padding-right: 10px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
  color: rgba(0, 0, 0, 0.9);
}
.bg-home-banner {
  background-color: #2e3192 !important;
  padding: 0.9em;
  font-weight: 600;
}

.btn-bar {
  background-color: #fff;
  border: 2px solid #fff;
  font-weight: 600;
  padding: 4px 16px;
  color: #2e3192 !important;
}
.card {
  box-shadow: 0px 0px 15px #00000029;
}
.card-hidden-xs {
  box-shadow: 0px 0px 15px #00000029;
}
.card-title {
  text-align: left;
  font: Semibold 30px/96px !important;
  color: #000617;
  opacity: 1;
}

.free-credits-form input,
.free-credits-form .form-check-label {
  background: #f5f4f7 0% 0% no-repeat padding-box;
  opacity: 1;
}

.free-credits-form .form-check-inline {
  width: 100%;
  margin-right: 0px;
  margin-bottom: 10px;
}

.free-credits-form .form-check-label {
  width: 100%;
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
}

.bulk-pricing .price-header {
  font-size: 1.75em;
  font-weight: 700;
}

.bulk-pricing .price-text {
  font-size: 0.75em;
  font-weight: 600;
}
/*  free credits page css */
.free-credits-img {
  background-image: url("/static/img/free_credits/free_credits_banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* min-height: 35em; */
}
.mobile-free-credits-heading {
  padding-top: 4em;
}
.free-credits .success-text {
  font-size: 1.19em;
  margin-bottom: 1rem;
}
.free-credits .note-text {
  font-size: 14px;
  line-height: 1.1rem;
}

/*  btn-picxy styles */

.h1-new {
  font-size: 3.25rem;
  line-height: 2.75rem;
  color: #373a3c;
}
.h2-new {
  font-size: 2.25rem;
  line-height: 2.75rem;
}
.f-24 {
  font-size: 1.5rem;
  color: #55595c;
}
.f-18 {
  font-size: 1em;
  color: #55595c;
}
.f-20 {
  font-size: 1.25em;
  color: #373a3c;
}
.bg-dull {
  background-color: #f2f2f2;
}
.bg-dull-secondary {
  background-color: #e0e1ff;
}
.bg-picxy-blue {
  background-color: #0d0e40;
  color: #fff;
}

/* pricing page styles */
.pricing .details-block {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 10px;
}

.pricing .block-divider {
  border-right: 1px solid #ddd;
}
.pricing .faq {
  cursor: pointer;
}
.pricing .txt-underline {
  color: #2e3192;
  text-decoration: underline;
  font-weight: 600;
}
.pricing-tabs {
  position: relative !important;
}
.pricing-tabs .plan-text {
  color: #2c2c2c;
  font-size: 18px;
}
.pricing-tabs .plan-price {
  color: #373baf;
  font-size: 45px;
  font-weight: 700;
  line-height: normal;
}
.pricing-tabs .plan-price sup {
  font-size: 27px;
  font-weight: 600;
  margin-left: 5px;
}
.pricing-tabs .custom-text {
  font-size: 17px;
  padding: 90px 0 80px !important;
}
.pricing-tabs .custome-border {
  border-right: 1px solid #cacaca;
}
.per-img-text {
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 500;
  padding: 6px;
}
.pricing-tabs .text-success {
  color: #28a745;
  font-size: 16px;
  font-weight: 600;
}
.pricing-tabs .nav-tabs {
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: rgba(247, 247, 247, 0.5);
  position: relative;
  padding: 0;
  margin: 0 10px;
}
.pricing-tabs .nav-tabs .nav-item {
  /* height: 54px; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: -1px;
  flex: auto;
  /* border-bottom: 1px solid #ddd; */
}

.pricing-tabs .nav-tabs .nav-link {
  /* color: #000;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  position: relative; */
  color: #000;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  position: relative;
  padding: 10px 8px;
  margin-top: 0;
  min-width: 40px;
}

.pricing-tabs .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #2e3193 !important;
  border-radius: 20px;
}

.pricing-tabs .nav-tabs .nav-link .bg-success {
  position: absolute;
  top: -19px;
  left: -11px;
  font-size: 10px;
  padding: 0 4px !important;
  width: 65px;
}
/* .pricing-tabs .pricing-card.active .nav-tabs .nav-link .bg-success{
  position: absolute;
  top: -27px;
  left: -17px !important;
  font-size: 10px;
  padding: 0 4px !important;
  width: 65px;
} */
.pricing-tabs .nav-link {
  display: block;
  padding: 0.012rem 0.2rem;
}
/* .pricing-tabs .nav-tabs .nav-link.active span {
  color: #fff;
  background-color: #2E3193;
  padding: 10px 8px;
  margin-top: 1px;
  border-radius: 13px;
} */
.pricing-tabs .best-seller {
  color: white;
  background: green;
  padding: 0px 5px;
  border-radius: 5px;
}
.pricing-tabs .best-seller-btn {
  color: #ffffff;
  background: #2e3192;
  border-radius: 5px;
  font-size: 14px;
  left: 0;
  right: 0;
  top: -4%;

  margin-left: auto;
  margin-right: auto;

  position: absolute;
  width: 30%;
  z-index: 999;
}
.bg-picxy-secondary {
  background-color: #e0e1ff;
}
.value-packs .pack-size {
  font-size: 20px;
  color: #2e3192;
  margin-bottom: 28px;
}

.pricing-card {
  border: 0;
  border-radius: 15px;
  position: relative;
}

.pricing-card .tabs {
  position: relative;
  z-index: 2;
}

.pricing-card .plan-selection {
  position: absolute;
  z-index: 3;
  top: 252px;
  text-align: center;
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}
.pricing-card .plan-selection label {
  display: flex;
  gap: 5px;
}
.pricing-card:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: url("../img/price-block-bg.png") no-repeat center center;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.pricing-card.active:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.pricing-card .tab-content {
  padding-bottom: 20px;
}

.card-block {
  transition: all 0.3s;
}

.card-block:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.pricing-card h1 {
  padding: 30px 10px;
  color: #2f3295;
  font-size: 17px;
  font-weight: 600;
  border-radius: 12px 12px 0px 0px;
  background: #f8f8f8;
  position: relative;
  z-index: 2;
}

.zoom-active .pricing-card h1 {
  padding: 30px 10px;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border-radius: 12px 12px 0px 0px;
  background: linear-gradient(180deg, #2e3192 0%, #3b3eb9 100%);
}

.pricing-card h4 {
  padding: 15px 0 32px 0;
  color: #272727;
  font-family: "Work Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  z-index: 2;
}

.pricing-card .btn-primary {
  border-radius: 30px;
  background: #2f3295;
  color: #fff;
  font-size: 16px;
}

.zoom-active {
  transform: scale(1.1);
}

.pricing-card .radio-button-container {
  color: rgba(0, 0, 0, 0.75);
  display: block;
  position: relative;
  padding-left: 45px;
  line-height: 25px;
  margin-bottom: 12px;
  margin-left: 30px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.pricing-card .radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.pricing-card .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}

/* On mouse-over, add a grey background color */
.pricing-card .radio-button-container:hover input ~ .checkmark {
  border-color: rgba(0, 0, 0, 0.5);
}

/* When the radio button is checked */
.pricing-card .radio-button-container input:checked ~ .checkmark {
  background-color: rgba(0, 0, 0, 0);
  border-color: teal;
}

@media (max-width: 1281px) and (max-width: 1600px) {
  .pricing-tabs .nav-tabs .nav-link {
    min-width: 42px;
  }
}

@media (max-width: 1280px) {
  .pricing-tabs .nav-tabs .nav-link {
    min-width: auto;
  }
  .pricing-tabs .plan-price {
    font-size: 30px;
  }
  .cart-counter {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1000px) {
  .price-data:before {
    bottom: 30px;
  }
}
@media (max-width: 767px) {
  .pricing {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  .price-data:before {
    width: auto !important;
    left: 20px !important;
    right: 20px !important;
    top: 0 !important;
    bottom: 0 !important;
    margin-bottom: 20px;
  }
  .zoom-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .pricing-tabs .nav-tabs .nav-link {
    font-size: 12px;
  }
  .pricing-tabs .plan-price {
    font-size: 35px;
  }
  .plan-selection {
    font-size: 11px;
  }
}
@media (max-width: 576px) {
  .h1-new {
    font-size: 2.25rem;
  }
  .f-24 {
    font-size: 1.25rem;
  }
  .h2-new {
    font-size: 1.875rem;
  }
  .pricing-tabs .custome-border {
    border: 0;
    border-bottom: 1px solid #cacaca;
  }
  .pricing-tabs .plan-price {
    font-size: 30px;
  }
  .per-img-text {
    font-size: 18px;
  }
  .card-hidden-xs {
    box-shadow: none;
    border: none;
  }
  .border-hidden-xs {
    border: 0px !important;
  }
}
/* .pricing-value-packs .nav-tabs .nav-item {
  height: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: -1px;
  flex: auto;
} */
/*  detail photo styles */

.picxy-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #2e3192;
  border-radius: 50%;
}
.picxy-radio-custom::before,
.picxy-radio-custom::after {
  top: 1.7em;
}

/*  hr with text helper css */
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.8;
}
.hr-text::after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}
.hr-text::before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  /* border-top: 2px solid #ddd; */
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

/* ------------------------
  Price Table
------------------------*/

.price-data:before {
  border-radius: 12px;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 70px;
  background: rgba(51, 55, 163, 0.68);
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.05));
}

.price-data-content {
  position: relative;
  z-index: 2;
  color: #fff;
  padding: 90px 10px 40px 20px;
  text-align: left;
}
.price-data-content h3 {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
}

.price-data-content ul {
  padding-left: 20px;
  margin-bottom: 30px;
}

.price-data-content ul li {
  font-size: 15px;
  line-height: 30px;
}

.price-data-content .icons-block {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-data-content .icons-block li {
  list-style-type: none;
  text-align: center;
  flex: 1;
}

.price-data-content .icons-block li img{
  width: 50px;
}

.price-data-content .icons-block li span {
  display: block;
  line-height: 23px;
  margin-top: 10px;
}

/* ---------------------testing---------------------------------------------- */
/* Styling the dropdown ul */
.navbar-nav.dropdown-list {
  display: block;
  position: absolute;
  background-color: #333;
  right: 0;
  top: 60px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
  width: 200px;
  padding: 10px;
}

/* Add transition effect */
.navbar-nav.dropdown-list li {
  list-style-type: none;
  padding: 10px 15px;
  border-bottom: 1px solid #f1f1f1;
  transition: all 0.3s ease;
}

.navbar-nav.dropdown-list li:last-child {
  border-bottom: none;
}

.navbar-nav.dropdown-list li:hover {
  background-color: none;
}

.changeColor {
  background-color: orange;
}
.profile-dropdown > a {
  border-right: 1px solid #ffffff40;
}
.profile-dropdown ul {
  position: absolute;
  right: 0;
  /* background: #fff; */
  background: rgba(49, 49, 146, 0.95);
  top: 46px;
  display: block !important;
}
.profile-dropdown li {
  width: 100%;
}
.profile-dropdown li a,
.profile-dropdown .dropdown-item {
  padding: 2px 10px;
  text-align: left;
  color: #fff;
}
.profile-dropdown li a:hover,
.profile-dropdown .dropdown-item:hover {
  color: #333 !important;
}
/* ---------------------testing---------------------------------------------- */

@media (max-width: 480px) {
  .price-data-content .icons-block {
      justify-content: center;
      flex-wrap: wrap;
  }
  .price-data-content .icons-block li{
    flex: 30% 0 0;
    margin-bottom: 12px;
  }
}

@media (max-width: 1400px) {
  .picxy-nav a{
    font-size: 15px;
    font-weight: 400;
  }
  .big-head {
    padding-top: 170px;
  }
}

@media (max-width: 1366px) {
  .picxy-nav a.nav-link {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.mobile-cart .cart-icon{
  position: relative;
  top: 11px;
}

.mobile-cart .cart-icon svg path, .inner-nav.scrolled .mobile-cart .cart-icon svg path{
  fill:#ffffff !important;
}

.inner-nav .mobile-cart .cart-icon svg path{
  fill:#000 !important;
}

